import React from 'react'
import tomato from '../img/tomato.jpg'

const Faq = () => {
  return (
    <div>

      <main id="main" className="main">
        <section className="section dashboard" id="faq">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card info-card sales-card">
                <div className="card-body" >
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          How to Irrigate Tomoato Crop?
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-4 col-sm-4">
                              <div className="faq-prod-img">
                                <img src={tomato} className="img-fluid" alt="" />
                              </div>
                            </div>
                            <div className="col-md-8 col-sm-8">
                              <strong>Answer :  </strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the, though the transition does limit overflow.
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          How to Irrigate Onion Crop?
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-4 col-sm-4">
                              <div className="faq-prod-img">
                                <img src={tomato} className="img-fluid" alt="" />
                              </div>
                            </div>
                            <div className="col-md-8 col-sm-8">
                              <strong>Answer :  </strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the, though the transition does limit overflow.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          How to Irrigate birjal Crop?
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-4 col-sm-4">
                              <div className="faq-prod-img">
                                <img src={tomato} className="img-fluid" alt="" />
                              </div>
                            </div>
                            <div className="col-md-8 col-sm-8">
                              <strong>Answer :  </strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the, though the transition does limit overflow.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </section>

      </main>

      <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
          <header className="mob-footer">
            <nav className="bottom-nav">
              <div className="bottom-nav-item active">
                <div className="bottom-nav-link">
                  <a href="feature_dashboard.html">
                    <i className="bi bi-shop"></i>
                    <span>Home</span>
                  </a>
                </div>
              </div>
              <div className="bottom-nav-item">
                <div className="bottom-nav-link">
                  <a href="product-gallery.html">
                    <i className="bi bi-handbag"></i>
                    <span>Product Gallery</span>
                  </a>
                </div>
              </div>

              <div className="bottom-nav-item">
                <div className="bottom-nav-link">
                  <a href="lab.html">
                    <i className="bi bi-eyedropper"></i>
                    <span>Lab</span>
                  </a>
                </div>
              </div>

              <div className="bottom-nav-item">
                <div className="bottom-nav-link">
                  <a href="#">
                    <i className="bi bi-share"></i>
                    <span>Share</span>
                  </a>
                </div>
              </div>

              {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}




            </nav>
          </header>
        </div>
      </section>


    </div>
  )
}

export default Faq
