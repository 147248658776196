import React from 'react'

const NewPlot = () => {
  return (
    <div>
      
      <main id="main" className="main">

<section className="section dashboard" id="Dashboard-form">
  <div className="row">

    
    <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card info-card sales-card">
            <div className="card-body" >
      <div className="row">
        <div className="col-md-12 col-sm-12 col-lg-12">
          <h5 className="card-title">Add New Plot</h5>
        </div>

        <div className="row">
        <div className="col-md-4 col-sm-4 col-lg-4 ">
          <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">व्हरायटी </label>
              <select className="form-select minimal" name="" id="">
                <option>व्हरायटी</option>
                <option></option>
                <option></option>
              </select>
             
        </div>
      </div>

      <div className="col-md-4 col-sm-4 col-lg-4 ">
        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">लागवडीची तारीख </label>
              <input type="text" className="form-control" id="inputNanme4" placeholder="लागवडीची तारीख "/>
      </div>
    </div>
       <div className="col-md-4 col-sm-4 col-lg-4 ">
        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">जमिनीचा प्रकार </label>
               <select className="form-select minimal" name="" id="">
                <option>जमिनीचा प्रकार</option>
                <option></option>
                <option></option>
              </select>
              
      </div>
    </div>

    <div className="col-md-4 col-sm-4 col-lg-4 ">
        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">सिंचन प्रकार  निवडा </label>
              <select className="form-select minimal" name="" id="">
                <option>सिंचन प्रकार  निवडा</option>
                <option></option>
                <option></option>
              </select>
             
      </div>
    </div>

    <div className="col-md-4 col-sm-4 col-lg-4 ">
        <div className="form-group ">
              <label htmlFor="inputNanme4" className="form-label">उद्देश  </label>
               <div className="form-inline radio-btn">
              <input className="form-check-input" type="radio" name="lang" value="show" checked/> <label>लोकल </label>
              <input className="form-check-input" type="radio" name="lang" value="hide"/> <label> एक्स्पोर्ट</label>
          </div> 
      </div>
    </div>

    <div className="col-md-4 col-sm-4 col-lg-4 ">
        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">प्लॉट चे  क्षेत्रफळ (एकर मध्ये )  </label>
              <input type="text" className="form-control" id="inputNanme4" placeholder="प्लॉट चे  क्षेत्रफळ "/>
      </div>
    </div>

    <div className="col-md-12 col-sm-12 col-lg-12">
        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">मागील वर्षी आलेल्या समस्या  </label>
              <textarea type="text" className="form-control" id="inputNanme4" placeholder="मागील वर्षी आलेल्या समस्या"></textarea>
      </div>
    </div>
    
     <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
      <a href="manage_farm.html" className="btn_new btn-success text-center">Submit</a>
      {/* <!-- <button className="btn btn-success text-center" type="submit">Submit </button> --> */}
      </div>
  </div>


      
    </div>
    </div>
  </div>
</div>


  </div>
</section>

</main>


<section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="feature_dashboard.html">
        <i className="bi bi-shop"></i>
        <span>Home</span>
      </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="product-gallery.html">
        <i className="bi bi-handbag"></i>
        <span>Product Gallery</span>
      </a>
      </div>
    </div>
     
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="lab.html">
        <i className="bi bi-eyedropper"></i>
        <span>Lab</span>
      </a>
      </div>
    </div>

    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
         <a href="#">
        <i className="bi bi-share"></i>
        <span>Share</span>
      </a>
      </div>
    </div>

  {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}

   

  
  </nav>
</header>
        </div>
    </section>



    </div>
  )
}

export default NewPlot
