
import React, {  useEffect, useLayoutEffect, useState } from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Navigate, useNavigate } from 'react-router-dom';




function AddDeliveryLocation({ google, locations = [] }) {
  const [lat, setLat] = useState("")
  const [lon, setLon] = useState("")

  const [area, setArea] = useState("")
  const [buildingname, setBuildingName] = useState("")
  const [landmark, setLandmark] = useState("")
  const [city, setCity] = useState("")
  const [mobileno, setMobileno] = useState("")

  const [area1, setArea1] = useState(false)
  const [buildingname1, setBuildingName1] = useState(false)
  const [landmark1, setLandmark1] = useState(false)
  const [city1, setCity1] = useState(false)
  const [mobileno1, setMobileno1] = useState(false)
  
  const  loarea=localStorage.getItem("area")
  const lobuildingname=localStorage.getItem("buildingname")
  const lolandmark=localStorage.getItem("landmark")
  const  locity=localStorage.getItem("city")
  const lomobileno=localStorage.getItem("MOBILE_NO")
  const navigate = useNavigate() 

  const [userAddress, setUserAddress] = useState(null);

  const Styles1 = {
    username: {
      color: "red"
    },
    username2: {
      display: "none"
    }

  }


 
const handlesubmit = (e) => {
 
  e.preventDefault();
  
    if (!area) {
      return setArea1(true)
    }
    else if (!buildingname) {
      return setBuildingName1(true)
    }
    else if (!landmark) {
      return setLandmark1(true)
    }
    else if (!city) {
      return setCity1(true)
    }
    else if (!mobileno) {
      return setMobileno1(true)
    }
    else if(mobileno.length<10){
      
      return setMobileno1(true)
    }

    else { 
      
      localStorage.setItem("area",area)
      localStorage.setItem("buildingname",buildingname)
      localStorage.setItem("landmark",landmark)
      localStorage.setItem("city",city)
      localStorage.setItem("MOBILE_NO",mobileno)
      navigate('/checkout')

    }
  }

  useLayoutEffect(() => {
   
    setArea(loarea)
    setBuildingName(lobuildingname)
    setLandmark(lolandmark)
    setCity(locity)
    setMobileno(lomobileno)
    
}
, [])  




useEffect(() => {
  navigator.geolocation.getCurrentPosition(position => {
    const { latitude, longitude } = position.coords;
    setLat(latitude)
    setLon(longitude)

    // use the latitude and longitude to get the user's address
    // fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBvTaVufSFg8S4Buq1d0KzYZMDW_qd0L7Q`)
    //   .then(response => response.json())
    //   .then(data => {
    //     setUserAddress(data.results[0].formatted_address);
    //   });
  });
}, []);





  return (
    <div>
      <Map
        google={google}
        containerStyle={{
          position: "static",
          width: "100%"

          // height:"100%"
        }}
        style={{
          width: "1100px",
          height:"400px",
          marginLeft:"400px"
        }}
        center={
          {
            lat: `${lat}`,
            lng: `${lon}`
          }
        }
        initialCenter={{
          lat: `${lat}`,
          lng: `${lon}`
        }}
        zoom={locations.length === 1 ? 16 : 13}
        disableDefaultUI={true}
      >
        {locations.map(
          coords => < Marker position={coords}  />
                    
        )}
        
         

      </Map>
      <br />
      <br />
      <br />
      <br />
      <br />
     

      <form >

        <div className=" form-group col-lg-3" style={{ marginTop: "300px" }} >

          <input type="text" id="area" onChange={(e) => { setArea(e.target.value) }} value={area} className="form-control" placeholder="Enter Area" />
          <div>
            <p style={area1 ? Styles1.username : Styles1.username2}>Select area</p>
          </div>
          </div>
          
        <div className="col-lg-3 form-group">
          <input type="text" onChange={(e) => { setBuildingName(e.target.value) }} value={buildingname}  className="form-control" placeholder="Flat no building name" />
          <div>
            <p style={buildingname1 ? Styles1.username : Styles1.username2}>Select building name</p>
          </div>
        </div>
        <div className="col-lg-3 form-group">
          <input type="text" onChange={(e) => { setLandmark(e.target.value) }} value={landmark} className="form-control" placeholder="Landmark" />
          <div>
            <p style={landmark1 ? Styles1.username : Styles1.username2}>Select landmark</p>
          </div>
        </div>
        <div className="col-lg-3 form-group">
          <input type="text" onChange={(e) => { setCity(e.target.value) }} value={city} className="form-control" placeholder="City" />
          <div>
            <p style={city1 ? Styles1.username : Styles1.username2}>Select city</p>
          </div>
        </div>
        <div className="col-lg-3 form-group">
          <input type="text" maxLength={10} onChange={(e) => { setMobileno(e.target.value) }}  value={mobileno} className="form-control" placeholder="Mobile no" />
          <div>
            <p style={mobileno1 ? Styles1.username : Styles1.username2}>Select mobile no</p>
          </div>
        </div>
        <div className='locationbtn'>
          <button type="submit" className="btnlocation" onClick={handlesubmit}>SAVE ADDRESS</button>

        </div>

      </form>

      <div>User's address: {userAddress}</div>;




    </div>
  )
}





export default GoogleApiWrapper({
  apiKey: "AIzaSyBvTaVufSFg8S4Buq1d0KzYZMDW_qd0L7Q"
})(AddDeliveryLocation);
