import React from 'react'
import tomato from '../img/tomato.jpg'

const QuestionAnswer = () => {
  return (
    <div>
      
      <main id="main" className="main">

    <section className="section dashboard" id="question-answer">
      <div className="row">

        <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="plot-add-button-blk">
                  <a href="add-question.html" className="button-style">प्रश्न विचारा </a>
                </div>
            </div>

        <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card info-card sales-card">
                <div className="card-body" >
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3">
                      <div className="question-answer-list-prod-img">
                        <img src={tomato} className="img-fluid" alt=""/>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                      <div className="question-answer-list-outer-blk">
                        <h5><strong>How to Irrigate Tomoato Crop?</strong></h5>
                        <p><strong>Answer: </strong>  It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the, though the transition does limit overflow.</p>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                     <div className="col-lg-3 col-md-3 col-sm-3">
                      <div className="question-answer-list-prod-img">
                        <img src={tomato} className="img-fluid" alt=""/>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                      <div className="question-answer-list-outer-blk">
                        <h5><strong>How to Irrigate Tomoato Crop?</strong></h5>
                        <p><strong>Answer: </strong>  It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the, though the transition does limit overflow.</p>
                      </div>
                    </div>  
                  </div>
                   <hr/>
                  <div className="row">
                     <div className="col-lg-3 col-md-3 col-sm-3">
                      <div className="question-answer-list-prod-img">
                        <img src={tomato} className="img-fluid" alt=""/>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9">
                      <div className="question-answer-list-outer-blk">
                        <h5><strong>How to Irrigate Tomoato Crop?</strong></h5>
                        <p><strong>Answer: </strong>  It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the, though the transition does limit overflow.</p>
                      </div>
                    </div>  
                  </div>

            
        </div>
      </div>
  </div>

      </div>
    </section>

  </main>

  <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="feature_dashboard.html">
        <i className="bi bi-shop"></i>
        <span>Home</span>
      </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="product-gallery.html">
        <i className="bi bi-handbag"></i>
        <span>Product Gallery</span>
      </a>
      </div>
    </div>
     
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="lab.html">
        <i className="bi bi-eyedropper"></i>
        <span>Lab</span>
      </a>
      </div>
    </div>

    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
         <a href="#">
        <i className="bi bi-share"></i>
        <span>Share</span>
      </a>
      </div>
    </div>

  {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}

   

  
  </nav>
</header>
        </div>
    </section>


    </div>
  )
}

export default QuestionAnswer
