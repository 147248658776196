import axios from 'axios'
import React,{useState} from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import {GET_DATA} from '../base_url/BaseUrl'
import {PLOT_MASTER} from '../base_url/BaseUrl'
import { Navigate, useNavigate } from 'react-router-dom'



const AddPlot = () => {

    const { setSideBar, sidebar } = useContext(AuthContextProvider)
    const [variety,setVariety] = useState([])
    const [selectedVariety,setSelectedVariety] = useState("")
    const [plantationDate,setPlantationDate] = useState("")
    const [soilType,setSelectedSoilType] = useState("")
    const [plotArea,setPlotArea] = useState("")
    const [lastYearProblems,setLastYearProblems] = useState("")
    const navigate = useNavigate();

    const USER_ID=localStorage.getItem("USER_ID")
    const CROP_ID=localStorage.getItem("CROP_ID")


    const Styles = {
        sidebar1:{
          marginLeft:""
        },
        sidebar2:{
          marginLeft:"00px"
        }
      }
    
      useEffect(() => {
        setSideBar(true)
      },[])


      useEffect(()=>{
        axios.post(GET_DATA,{
            START:"0",
            END:"10000",
            WORD:"",
            GET_DATA:"CropVariety_List",
            ID1:"1",
            ID2:"",
            ID3:"",
            STATUS:"",
            START_DATE:"",
            END_DATE:"",
            EXTRA1:"",
            EXTRA2:"",
            EXTRA3:"",
            LANG_ID:"1"
        }).then((response)=>{
            console.log("VarietyListAPI :",response.data.DATA)
            setVariety(response.data.DATA)
        })
      },[])


      const addPlotAPI=()=>{
        console.log("Selected Variety Is :",selectedVariety)
        console.log("Selected Plantation Date Is :",plantationDate)
        console.log("Selected Soil Type Is :",soilType)
        console.log("Selected plotArea Is :",plotArea)
        console.log("Selected lastYearProblems Is :",lastYearProblems)
        console.log("Selected CropId Is :",CROP_ID)
        console.log("Selected USER_ID Is :",USER_ID)



        axios.post(PLOT_MASTER,{
          USER_ID:USER_ID,
          PLOT_ID:"1",
          CROP_ID:CROP_ID,
          CROPVARIETY_ID:selectedVariety,
          CHATANI_ID:"",
          ST_ID:soilType,
          WATER_SOURCE:"",
          PLOT_SRUCTURE:"",
          METHOD_OF_WATER:"",
          CROP_DISTANCE:"",
          EXPORT_LOCAL:"",
          PLOT_AREA:plotArea,
          LATITUDE:"",
          LONGITUDE:"",
          PREVIOUS_YEAR_PROBLEM:lastYearProblems,
          SOWING_DATE:plantationDate,
          TASK:"",
          EXTRA1:"",
          EXTRA2:"",
          EXTRA3:"",
          LANG_ID:"1"
        }).then((responce)=>{
          console.log("selected API Call Plot Reg :",responce.data)
          console.log("selected API Call Plot Reg aaa :",responce.data.ResponseCode)
          if(responce.data.ResponseCode == "0")
          {
            navigate("/featureDashbord")
          }
          else
          {
            alert("Plot Registration Failed..")
          }
        })
      }


  

  return (
    <div>
      

      <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>

      

<section className="section dashboard" id="Dashboard-form">
  <div className="row">

   
    <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card info-card sales-card">
            <div className="card-body" >
      <div className="row">
          




        <div className="row active ">
      
      <div className="xdemo1" id="xaddress1">
      <div className="row active">

        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
             <label for="inputNanme4" className="form-label">व्हरायटी</label>
              <select className="form-select minimal" onChange={(e)=>setSelectedVariety(e.target.value)} name="state_name" value={selectedVariety} id="state_name" districturl="/district/">
                 <option value="SelectedVariety">व्हरायटी</option>
                 {
                    
                    variety.map((i,index)=>{
                        return(
                            <option value={i.V_ID}>{i.VL_NAME}</option>
                        )
                    })
                 }  
                                                
                                                
                </select>
        </div>
      </div>
         <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
             <label for="inputNanme4" className="form-label">लागवडीची तारीख </label>
             <input type="date" onChange={(e)=>setPlantationDate(e.target.value)} className="form-select"/>
  
        </div>
      </div>
          <div className="col-md-6 col-sm-6 col-lg-6">
            <div className="form-group">
             <label for="inputNanme4" className="form-label">मातीचा प्रकार</label>
              <select className="form-select minimal" onChange={(e)=>setSelectedSoilType(e.target.value)} name="taluka_name" id="taluka_name" mastervillageurl="/master_village/">

              <option value="1">मातीचा प्रकार</option>
              <option value="2">मध्यम काळी</option>
              <option value="3">लाल</option>
              <option value="4">वालुकामय चिकणमाती</option>
              <option value="5">चिकणमाती</option>


   

            </select>
        </div>
      </div>
          <div className="col-md-6 col-sm-6 col-lg-6">
            <div className="form-group">
             <label for="inputNanme4" className="form-label">प्लॉट चे क्षेत्रफळ (एकर मध्ये)</label>
             <input className="form-control minimal" onChange={(e)=>setPlotArea(e.target.value)} placeholder='प्लॉट चे  क्षेत्रफळ (एकर मध्ये )' name="master_village" id="master_village"/>

    
        </div>
      </div>

      <div className="col-md-6 col-sm-6 col-lg-6">
            <div className="form-group">
             <label for="inputNanme4" className="form-label">मागील वर्षी आलेल्या समस्या </label>
             <textarea className="form-control minimal" onChange={(e)=>setLastYearProblems(e.target.value)} placeholder='मागील वर्षी आलेल्या समस्या' name="master_village" id="master_village"/>

    
        </div>
      </div>
      
   </div> 

   
     
    </div>
     <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
      <a className="btn_new btn-success text-center" onClick={()=>addPlotAPI()}>Submit</a>
      </div>
  </div>


      
    </div>
    </div>
  </div>
</div>

  </div>
</section>

</main>

    </div>
  )
}

export default AddPlot
