import React,{useState} from 'react'
import { createContext } from 'react'


export const AuthContextProvider = createContext()
const AuthContext = ({children}) => {

    const [sidebar,setSideBar]=useState(false)
    const[navBar,setNavBar] = useState(true)


  return (
    <AuthContextProvider.Provider value={{sidebar,setSideBar,navBar,setNavBar}}>
    {children}
    </AuthContextProvider.Provider>
  )
}

export default AuthContext



