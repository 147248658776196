import React,{useState, Com} from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { Navigate, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import {GET_DATA,PRODUCT_CATEGORY,GET_PRODUCT_LIST,MEDIA_PLAYER_CATEGORY} from '../base_url/BaseUrl'
import * as mdb from 'mdb-ui-kit'; 
import { Input } from 'mdb-ui-kit';
import demo from '../img/produc-gallery1.jpg';
import arrow from '../img/right3.png'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';



const KnowledgeHub = () => {
  
  const [data,setdata]=useState([])
  const [productdata,setProductData]=useState([])
  const [loading,setLoading]=useState(false)
  const { setSideBar, sidebar } = useContext(AuthContextProvider)

  const UserId=localStorage.getItem("USER_ID")   
  console.log(UserId)

  const navigate = useNavigate();
  const catName=""



  const Styles = {
    sidebar1:{
      marginLeft:""
    },
    sidebar2:{
      marginLeft:"00px"
    }
  }

  useEffect(() => {
    setSideBar(true)
  },[])



   

const showProducts=()=>{

}



//   useEffect(()=>{
//     setLoading(true)
//     axios.post(`${GET_PRODUCT_LIST}?CAT_ID=23`,
//     {
//       START:"0",
//       END:"10000",
//       WORD:"None",
//       LATITUDE:"1",
//       LONGITUDE:"1",
//       LANG_ID:"2",
//       USER_ID:UserId,
//       TYPE:"ALL"
//     }).then((responce)=>{
//       console.log(responce.data)
//       setProductData(responce.data.DATA)
//       catName=responce.data.CAT_NAME
//       setLoading(false)
//     })

//   },[])

const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };






 

  return (
    <div>
      {loading ? (
        <span style={{marginLeft:"800px" , position:"relative", top:"300px"}}>
        <RotatingLines
        strokeColor="grey"
        strokeWidth="3"
        animationDuration="1.5"
        width="96"
        visible={true}
      />
      </span>
      ):(
        <>
        <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>

        <section className="section dashboard" id="disease">



          <div className="xrow">
     
          <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card info-card sales-card">
                    <div className="card-body">
    
                    <div className="row" >
                    <div className="col-lg-12 col-md-12 co-sm-12 col-12"   style={{display:"flex",overflowY:"hidden" , gap:"15px"}} >
                      
                     <a>
<div className='plot-add-button-blk'>
<a className="button-style" >Rodenticides</a>


</div>
</a>
<a>
<div className='plot-add-button-blk'>
<a className="button-style" >Herbicide</a>


</div>
</a>
<a>
<div className='plot-add-button-blk'>
<a className="button-style" >Insecticides</a>


</div>
</a>
                     </div>

          
                    </div>
                </div>
          </div>
      </div>


      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" >
            <Tab label="images" value="1" style={{color: "green",fontSize: "17px"}} />
            <Tab label="video" value="2" style={{color: "green",fontSize: "17px"}} />
      
          </TabList>
        </Box>
        <TabPanel value="1" >
        <div className="row " id="product-gallery">
        <div className="col-lg-3 col-md-3 col-sm-3 col-6 " >
      <div className="card info-card sales-card">
          <div className="card-body">
            <a 
            // onClick={()=>goProductDetails(i)}
            >
            <img src={demo} className="img-fluid" alt=""/>
            <div className="product-name-blk">
              <div className="d-flex justify-content-between">
            <div className="product-title">
              <h5>Pesticide Ingredients</h5>
              
            </div>
            
          </div>
            
          </div>
           </a>
          </div>

      </div>
    </div>


    <div className="col-lg-3 col-md-3 col-sm-3 col-6 " >
      <div className="card info-card sales-card">
          <div className="card-body">
            <a 
            // onClick={()=>goProductDetails(i)}
            >
            <img src={demo} className="img-fluid" alt=""/>
            <div className="product-name-blk">
              <div className="d-flex justify-content-between">
            <div className="product-title">
              <h5>Pesticide Ingredients</h5>
              
            </div>
            
          </div>
            
          </div>
           </a>
          </div>

      </div>
    </div>
</div>



        </TabPanel>
        <TabPanel value="2">
        <div className="row " id="product-gallery">
        <div className="col-lg-3 col-md-3 col-sm-3 col-6 " >
      <div className="card info-card sales-card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 text-center">
              <iframe className='product-vedio' src='https://www.youtube.com/embed/E7wJTI-1dvQ'
        frameborder='0'
        allow='autoplay; encrypted-media'
        allowfullscreen
        title='video'
/>
              </div>
            </div>
            <a 
            // onClick={()=>goProductDetails(i)}
            >




            {/* <img src={demo} className="img-fluid" alt=""/> */}
            <div className="product-name-blk">
              <div className="d-flex justify-content-between">
            <div className="product-title">
              <h5>post-harvests</h5>
              
            </div>
            
          </div>
            
          </div>
           </a>
          </div>

      </div>
    </div>
  
    <div className="col-lg-3 col-md-3 col-sm-3 col-6 " >
      <div className="card info-card sales-card">
          <div className="card-body">
            <div className='row'>
              <div className='col-12 text-center'>
          <iframe className='product-vedio' src='https://www.youtube.com/embed/E7wJTI-1dvQ'
        frameborder='0'
        allow='autoplay; encrypted-media'
        allowfullscreen
        title='video'
/>
</div>
</div>

<a 
            // onClick={()=>goProductDetails(i)}
            >


            {/* <img src={demo} className="img-fluid" alt=""/> */}
            <div className="product-name-blk">
              <div className="d-flex justify-content-between">
            <div className="product-title">
              <h5>post-harvests</h5>
              
            </div>
            
          </div>
            
          </div>
           </a>
          </div>

      </div>
    </div>
  </div>
 




        </TabPanel>
        
      </TabContext>
    </Box>


  
 
  {/* <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card info-card sales-card">
                    <div className="card-body">
    
                    <div className="row" >
                    <div className="col-lg-12 col-md-12 co-sm-12 col-12"   style={{display:"flex",overflowY:"hidden" , gap:"15px"}} >
                      {
                        data.map((i,index)=>{
                          return(
                            
                            <a 
                            onClick={()=>showProducts(i)} 
                            key={index}>

                            <div className="plot-add-button-blk">
                              <a  className="button-style"  style={{color:"white"}}>{i.CAT_NAME}</a>
                            </div>

                              
                            </a>
                          
                          )
                        })
                      }
                    


                      </div>
    
          
                    </div>
                </div>
          </div>
      </div>  */}
    
    {/* <div className='text-center'><h2 style={{color:"green"}}><b>{catName}</b></h2></div> */}
    
        
    
          </div>
          
        </section>
    
      </main>
      <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
            <div className="container">
                <header className="mob-footer">
      <nav className="bottom-nav">
        <div className="bottom-nav-item active">
          <div className="bottom-nav-link">
            <a href="./featureDashbord">
            <i className="bi bi-shop"></i>
            <span>Home</span>
          </a>
          </div>
        </div>
        <div className="bottom-nav-item">
          <div className="bottom-nav-link">
            <a href="./productGallery">
            <i className="bi bi-handbag"></i>
            <span>Product Gallery</span>
          </a>
          </div>
        </div>
         
        <div className="bottom-nav-item">
          <div className="bottom-nav-link">
            <a href="./lab">
            <i className="bi bi-eyedropper"></i>
            <span>Lab</span>
          </a>
          </div>
        </div>
    
        <div className="bottom-nav-item">
          <div className="bottom-nav-link">
             <a href="#">
            <i className="bi bi-share"></i>
            <span>Share</span>
          </a>
          </div>
        </div>
    
      {/* <!-- <div className="bottom-nav-item">
          <div className="bottom-nav-link">
            <i className="bi bi-question"></i>
            <span>Questions</span>
          </div>
        </div> --> */}
    
       
    
      
      </nav>
    </header>
            </div>
        </section>
      
    
        </>
      )}


    </div>
  )
}

export default KnowledgeHub

