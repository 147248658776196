import React, { useState } from 'react'
import broken_cloud from '../img/fourteen_weather.webp'
import  overcast_clouds from '../img/third_weather.webp'
import  light_rain from '../img/sixth_weather.webp'
import  clear_sky from  '../img/first_weather.webp'
import  scattered_clouds from  '../img/eighth_weather.webp'
import  few_clouds from  '../img/twelve_weather.webp'
import moment from 'moment/moment'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import axios from 'axios'
import { useEffect } from 'react'
import {WEATHER_URL} from '../base_url/BaseUrl'

function Weather() {
    const [new1, setNew] = useState([])
    const [new1w, setNeww] = useState([])
    const [weather1, setWeather] = useState([])
    const [weather2, setWeather2] = useState([])
    const [weather3, setWeather3] = useState([])
    const [weather4, setWeather4] = useState([])
    const [weather5, setWeather5] = useState([])
    const [weather6, setWeather6] = useState([])
    
    const [wind, setWind] = useState([])
    const [clouds, setClouds] = useState([])

    const { setSideBar, sidebar } = useContext(AuthContextProvider)

    const Styles = {
        sidebar1:{
          marginLeft:""
        },
        sidebar2:{
          marginLeft:"00px"
        }
      }

      useEffect(() => {
        setSideBar(true)
      },[])
    
      useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position){  
            console.log("position.coords.latitude",position.coords.latitude);
            console.log("position.coords.longitude",position.coords.longitude);
            axios.get(WEATHER_URL)
                .then((res) => {                   
                    const data = res.data.list[0]
                    console.log("data",data);
                    console.log(res.data.list);
                    // console.log(res.data.weather[0].description);
                    setWeather3(res.data.list[6].dt_txt.slice(0, 10))
                    setWeather4(res.data.list[14].dt_txt.slice(0, 10))
                    setWeather5(res.data.list[22].dt_txt.slice(0, 10))
                    setWeather6(res.data.list[30].dt_txt.slice(0, 10))
                    setWeather(data)
                    setNew(res.data.list)
                    setWeather2(data.main)
                    setWind(data.wind)
                    setClouds(data.dt_txt.slice(11, 16));
                    setNeww(data.weather[0]);
                    console.log("weather",data.weather[0]);
                //    res.data.list.forEach((i)=>{
                //     i.weather.forEach((i)=>{
                //     console.log(i.description);
                //     });
                //    })
                res.data.list.forEach((i)=>{
                    i.weather.forEach((i)=>{
                    console.log(i.description);                
                    });
                   })
                })
        })
    }, [weather3, weather4, weather5, weather6, new1, weather2, wind, clouds])




    const today = moment(weather3).format("DD/MM/YYYY")
    const today1 = moment(weather4).format("DD/MM/YYYY")
    const today2 = moment(weather5).format("DD/MM/YYYY")
    const today3 = moment(weather6).format("DD/MM/YYYY")


  return (
    <div className='main'>
    <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>
        <section className="section dashboard" id="disease">
            <div className='container'>
                <div className="row">
                    <div className="col-lg-12 col-md-5 col-sm-6">
                        <div className='background-img'>
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-6">
                                    <div className="text-center mar-left">
                                        <h6 className='weather-today-climate' style={{ color: "white"}}>आजचे हवामान </h6>
                                        <h5  className='weather-Last-update' style={{ color: "white"}} >शेवटचे अद्यावत : {clouds} PM</h5>
                                       
                                       
                                       
                                       
                                       
                                        {/* <img className='img-fluid img-weather' src={weather} alt="" /> */}
                                        {new1w.description==="broken clouds" && <img  className='img-fluid img-wweather' style={{height: "70px",width: "107px",marginLeft: "1px", marginTop: "-3px"}}  src={broken_cloud} alt="" /> }
                                   {new1w.description==="overcast clouds" && <img  className='img-fluid img-wweather' style={{height: "70px",width: "107px",marginLeft: "1px", marginTop: "-3px"}} src={overcast_clouds} alt="" />}
                                   {new1w.description==="light rain" && <img   className='img-fluid img-wweather'style={{height: "70px",width: "107px",marginLeft: "1px", marginTop: "-3px"}} src={light_rain} alt="" />}
                                   {new1w.description==="clear sky" &&<img  className='img-fluid img-wweather' style={{height: "70px",width: "107px",marginLeft: "1px", marginTop: "-3px"}} src={clear_sky} alt="" /> }
                                   {new1w.description==="scattered clouds" && <img  className='img-fluid img-wweather' style={{height: "70px",width: "107px",marginLeft: "1px", marginTop: "-3px"}} src={scattered_clouds} alt="" />}
                                   {new1w.description==="few clouds" && <img  className='img-fluid img-wweather' style={{height: "70px",width: "107px",marginLeft: "1px", marginTop: "-3px"}} src={few_clouds} alt="" />}
                                   
                                
                                       <h5 className='degree' style={{ color: "white"}}>{(weather2.temp - 273.15).toFixed(2)} &#176;C</h5>
                                       <div className='wdescription' style={{ color: "white"}}>{new1w.description}</div>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="col-lg-12 col-md-12 col-sm-6">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                <p className='weather-font-size' style={{ color: "white", marginLeft: "15px",    marginTop: "13px" }}>किमान : {(weather2.temp_min - 273.15).toFixed(2)} &#176;C</p>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                <p className='weather-font-size' style={{ color: "white",    marginTop: "10px" }}>कमाल : {(weather2.temp_max - 273.15).toFixed(2)} &#176;C</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="col-lg-12 col-md-12 col-sm-6">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                <p className='weather-font-size' style={{ color: "white", marginLeft: "15px" }}>आर्द्रता : {weather2.humidity}%</p>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                <p className='weather-font-size' style={{ color: "white" }}>वाऱ्याचा वेग : {wind.speed} mph</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className="container">
                                    <div className="col-lg-12 col-md-12 col-sm-6">
                                        <p className='weather-font-size' style={{ color: "white", marginLeft: "15px",marginBottom: "40px" }}>दबाव : {weather2.pressure} hpa</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="container">
                <h5 style={{ padding: "10px", fontWeight: "bold" }}>पुढील हवामान अंदाज</h5>
                <div className="row">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div style={{ border: "1px solid gray", borderRadius: "5px" }}>
                            <div className='container'>
                                <div className="row bgcolour">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6" style={{ background: "rgba(0, 128, 0, 0.46)"}} >
                                        <p className='wdate' >{today}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6"    style={{ background: "rgba(0, 128, 0, 0.46)"}}>
                                        <p  style={{ float: "right", marginRight: "13px", padding: "15px 0 0",marginTop:"0px", marginBottom:"15px" }} >उद्याचे हवामान</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className='table-responsive'>
                                            <table className='table' width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>12.00 AM</td>
                                                        <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>03.00 AM</td>
                                                        <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>06.00 AM</td>
                                                        <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>09.00 AM</td>
                                                        <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>12.00 AM</td>
                                                        <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>03.00 AM</td>
                                                        <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>06.00 AM</td>
                                                        <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>09.00 AM</td>
                                                    </tr>
                                                    <tr>
                                                    {new1.slice(6,14).map((i) => {
                                                                console.log("i",i);
                                                                return(
                                                                    <>
                                                                    {i.weather[0].description === "broken clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={broken_cloud} alt="" /></td>}
                                                                    {i.weather[0].description === "overcast clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={overcast_clouds} alt="" /></td>}
                                                                    {i.weather[0].description === "light rain" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={light_rain} alt="" /></td>}
                                                                    {i.weather[0].description === "clear sky" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={clear_sky} alt="" /></td>}
                                                                    {i.weather[0].description === "scattered clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={scattered_clouds} alt="" /></td>}
                                                                    {i.weather[0].description === "few clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={few_clouds} alt="" /></td>}
                                                                    
                                                                    </>
                                                                )
                                                            })}
                                                        {/* <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={weather} alt="" /></td>
                                                        <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={weather} alt="" /></td>
                                                        <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={weather} alt="" /></td>
                                                        <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={weather} alt="" /></td>
                                                        <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={weather} alt="" /></td>
                                                        <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={weather} alt="" /></td>
                                                        <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={weather} alt="" /></td>
                                                        <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={weather} alt="" /></td> */}
                                                    </tr>
                                                    <tr>
                                                        {new1.slice(6, 14).map((i) => {
                                                            return (
                                                                <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}>{(i.main.temp - 273.15).toFixed(2)} &#176;C</td>
                                                            )
                                                        })}
                                                    </tr>
                                                    <tr>
                                                        {new1.slice(6, 14).map((i) => {
                                                            return (
                                                                <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}>{i.weather[0].description}</td>

                                                            )
                                                        })}

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                    <div className="row">
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div style={{ border: "1px solid gray", borderRadius: "5px" }}>
                                <div className='container'>
                                    
                                    <div className="row bgcolour">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6" style={{ background: "rgba(0, 128, 0, 0.46)"}}>
                                            <div className='weather-third-day-date'>  
                                            <p className='wdate' >{today1}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6" style={{ background: "rgba(0, 128, 0, 0.46)"}} >
                                            <p style={{ float: "right", marginRight: "13px", padding: "15px 0 0",marginTop:"0px", marginBottom:"15px" }} >तिसऱ्या दिवसाचे हवामान  </p>
                                        </div>
                                    </div>
                                   
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='table-responsive'>
                                                <table className='table' width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>12.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>03.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>06.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>09.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>12.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>03.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>06.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>09.00 AM</td>
                                                        </tr>
                                                        <tr>
                                                        {new1.slice(14,22).map((i) => {
                                                                return(
                                                                    <>
                                                                    {i.weather[0].description === "broken clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={broken_cloud} alt="" /></td>}
                                                                    {i.weather[0].description === "overcast clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={overcast_clouds} alt="" /></td>}
                                                                    {i.weather[0].description === "light rain" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={light_rain} alt="" /></td>}
                                                                    {i.weather[0].description === "clear sky" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={clear_sky} alt="" /></td>}
                                                                    {i.weather[0].description === "scattered clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={scattered_clouds} alt="" /></td>}
                                                                    {i.weather[0].description === "few clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={few_clouds} alt="" /></td>}
                                                                    
                                                                    </>
                                                                )
                                                            })}                                                                   
                                                        </tr>
                                                        <tr>
                                                            {new1.slice(14, 22).map((i) => {
                                                                return (
                                                                    <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}>{(i.main.temp - 273.15).toFixed(2)} &#176;C</td>
                                                                )
                                                            })}
                                                        </tr>
                                                        <tr>
                                                            {new1.slice(14, 22).map((i) => {
                                                                return (
                                                                     <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}>{i.weather[0].description}</td>
                                                                )
                                                            })}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                    <div className="row">
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div style={{ border: "1px solid gray", borderRadius: "5px" }}>
                                <div className='container'>
                                    <div className="row bgcolour">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6" style={{ background: "rgba(0, 128, 0, 0.46)"}}>
                                            <p className='wdate'>{today2}</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6" style={{ background: "rgba(0, 128, 0, 0.46)"}} >
                                            <p style={{ float: "right", marginRight: "13px", padding: "15px 0 0",marginTop:"0px", marginBottom:"15px" }} > चौथ्या दिवसाचे हवामान </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='table-responsive'>
                                                <table className='table' width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>12.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>03.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>06.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>09.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>12.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>03.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>06.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>09.00 AM</td>
                                                        </tr>
                                                        <tr>
                                                        {new1.slice(22,30).map((i) => {
                                                                return(
                                                                    <>
                                                                    {i.weather[0].description === "broken clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={broken_cloud} alt="" /></td>}
                                                                    {i.weather[0].description === "overcast clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={overcast_clouds} alt="" /></td>}
                                                                    {i.weather[0].description === "light rain" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={light_rain} alt="" /></td>}
                                                                    {i.weather[0].description === "clear sky" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={clear_sky} alt="" /></td>}
                                                                    {i.weather[0].description === "scattered clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={scattered_clouds} alt="" /></td>}
                                                                    {i.weather[0].description === "few clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={few_clouds} alt="" /></td>}
                                                                    
                                                                    </>
                                                                )
                                                            })}                                                                    
                                                        </tr>
                                                        <tr>
                                                            {new1.slice(22, 30).map((i) => {
                                                                return (
                                                                    <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}>{(i.main.temp - 273.15).toFixed(2)} &#176;C</td>
                                                                )
                                                            })}
                                                        </tr>
                                                        <tr>
                                                           {new1.slice(22, 30).map((i) => {
                                                                return (
                                                                    <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}>{i.weather[0].description}</td>

                                                                )
                                                            })} 
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                    <div className="row">
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div style={{ border: "1px solid gray", borderRadius: "5px" }}>
                                <div className='container'>
                                    
                                    <div className="row bgcolour">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6"style={{ background: "rgba(0, 128, 0, 0.46)"}} >
                                            <p className='wdate' >{today3}</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6" style={{ background: "rgba(0, 128, 0, 0.46)"}}>
                                            {/* <p style={{ float: "right", marginRight: "13px", padding: "15px 0 0" }}>{t('पाचव्या दिवसाचे हवामान')}</p> */}
                                            <p style={{ float: "right", marginRight: "13px", padding: "15px 0 0",marginTop:"0px", marginBottom:"15px" }} >पाचव्या दिवसाचे हवामान</p>
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='table-responsive'>
                                                <table className='table' width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>12.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>03.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>06.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>09.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>12.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>03.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>06.00 AM</td>
                                                            <td style={{ width: "86px", textAlign: "center", borderBottom: "0" }}>09.00 AM</td>
                                                        </tr>
                                                        <tr>
                                                            {new1.slice(30,38).map((i) => {
                                                                return(
                                                                    <>
                                                                    {i.weather[0].description === "broken clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={broken_cloud} alt="" /></td>}
                                                                    {i.weather[0].description === "overcast clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={overcast_clouds} alt="" /></td>}
                                                                    {i.weather[0].description === "light rain" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={light_rain} alt="" /></td>}
                                                                    {i.weather[0].description === "clear sky" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={clear_sky} alt="" /></td>}
                                                                    {i.weather[0].description === "scattered clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={scattered_clouds} alt="" /></td>}
                                                                    {i.weather[0].description === "few clouds" && <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}><img src={few_clouds} alt="" /></td>}
                                                                    
                                                                    </>
                                                                )
                                                            })}                                                                    
                                                        </tr>
                                                        <tr>
                                                            {new1.slice(30, 38).map((i) => {
                                                                return (
                                                                    <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}>{(i.main.temp - 273.15).toFixed(2)} &#176;C</td>
                                                                )
                                                            })}
                                                        </tr>
                                                        <tr>
                                                            {new1.slice(30, 38).map((i) => {
                                                                return (
                                                                    <td style={{ textAlign: "center", padding: "0", borderBottom: "0" }}>{i.weather[0].description}</td>
                                                                )
                                                            })}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    </main>
</div>
  )
}

export default Weather
