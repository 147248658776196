import React,{useState, Com} from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { Navigate, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import {PRODUCT_CATEGORY,GET_PRODUCT_LIST} from '../base_url/BaseUrl'



const ProductGallery = () => {
  
  const [data,setdata]=useState([])
  const [productdata,setProductData]=useState([])
  const [loading,setLoading]=useState(false)
  const { setSideBar, sidebar } = useContext(AuthContextProvider)

  const UserId=localStorage.getItem("USER_ID")
  console.log(UserId)

  const navigate = useNavigate();
  const catName=""



  const Styles = {
    sidebar1:{
      marginLeft:""
    },
    sidebar2:{
      marginLeft:"00px"
    }
  }

  useEffect(() => {
    setSideBar(true)
  },[])




  useEffect(()=>{
    setLoading(true)

    axios.post(PRODUCT_CATEGORY,{
      START:"0",
      END:"10000",
      WORD:"None",
      LATITUDE:"1",
      LONGITUDE:"1",
      LANG_ID:"2",
      USER_ID:UserId,
      TYPE:"ALL"
    }).then((res)=>{
      console.log(res.data)
      setdata(res.data.DATA)
      setLoading(false)
    })
  },[])


  useEffect(()=>{
    setLoading(true)
    axios.post(`${GET_PRODUCT_LIST}?CAT_ID=23`,
    {
      START:"0",
      END:"10000",
      WORD:"None",
      LATITUDE:"1",
      LONGITUDE:"1",
      LANG_ID:"2",
      USER_ID:UserId,
      TYPE:"ALL"
    }).then((responce)=>{
      console.log(responce.data)
      setProductData(responce.data.DATA)
      catName=responce.data.CAT_NAME
      setLoading(false)
    })

  },[])



  const showProducts=(i)=>{
    console.log(i)
 axios.post(`${GET_PRODUCT_LIST}?CAT_ID=${i.CAT_ID}`,{
    START:"0",
    END:"10000",
    WORD:"None",
    LATITUDE:"1",
    LONGITUDE:"1",
    LANG_ID:"2",
    USER_ID:UserId,
    TYPE:"ALL"
  }).then((responce)=>{
    console.log(responce.data)
    setProductData(responce.data.DATA)
    catName=responce.data.CAT_NAME
  })
  }


  const goProductDetails=(i)=>{

navigate("/productDetails",{state:i})

  }

 

  return (
    <div>
      {loading ? (
        <span style={{marginLeft:"800px" , position:"relative", top:"300px"}}>
        <RotatingLines
        strokeColor="grey"
        strokeWidth="3"
        animationDuration="1.5"
        width="96"
        visible={true}
      />
      </span>
      ):(
        <>
        <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>

        <section className="section dashboard" id="disease">



          <div className="xrow">
    
            <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card info-card sales-card">
                    <div className="card-body">
    
                    <div className="row" >
                    <div className="col-lg-12 col-md-12 co-sm-12 col-12"    >
                      <div className='categoryname' style={{display:"flex", gap:"15px",overflowX: "scroll",overflowY: "hidden",
    whiteSpace: "nowrap"}} >
                      <ul>
                           
                      
                      {
                        data.map((i,index)=>{
                          return(
                            <li style={{display:"inline-block" ,padding:"0px 15px"}}>
                            <a onClick={()=>showProducts(i)} key={index}>

                            <div className="plot-add-button-blk" >
                            
                              <a  className="button-style">{i.CAT_NAME}</a>
                             
                            </div>

                             
                            </a>
                            </li>
                          )
                        })
                      }
                     
                
                              </ul>
                      </div>
                      </div>
          
                    </div>
                </div>
          </div>
      </div>
    
    <div className='text-center'><h2 style={{color:"green"}}><b>{catName}</b></h2></div>
    
        <div className="row " id="product-gallery">
    
        {
      productdata.map((i,index)=>{
        return(
          <div className="col-lg-3 col-md-3 col-sm-3 col-6 " key={index}>
          <div className="card info-card sales-card">
              <div className="card-body">
                <a onClick={()=>goProductDetails(i)}>
                <img src={
                  i.P_IMAGE
                  } className="img-fluid" alt=""/>
                <div className="product-name-blk">
                  <div className="d-flex justify-content-between">
                <div className="product-title">
                  <h5>{i.P_NAME.slice(0,10)}{i.P_NAME.length>=10 ? "..." : ""}</h5>
                  
                </div>
                <div className="product-price">
                  {"Rs."+i.P_PRICE}
                </div>
              </div>
                <div className="btn_new read-more ">
                 <button type="button"> पुढे पहा </button> 
                </div>   
              </div>
               </a>
              </div>
    
          </div>
        </div>
        )
      })
    }
    
    
        </div>
    
    
    
          </div>
          
        </section>
    
      </main>
    
      <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
            <div className="container">
                <header className="mob-footer">
      <nav className="bottom-nav">
        <div className="bottom-nav-item active">
          <div className="bottom-nav-link">
            <a href="./featureDashbord">
            <i className="bi bi-shop"></i>
            <span>Home</span>
          </a>
          </div>
        </div>
        <div className="bottom-nav-item">
          <div className="bottom-nav-link">
            <a href="./productGallery">
            <i className="bi bi-handbag"></i>
            <span>Product Gallery</span>
          </a>
          </div>
        </div>
         
        <div className="bottom-nav-item">
          <div className="bottom-nav-link">
            <a href="./lab">
            <i className="bi bi-eyedropper"></i>
            <span>Lab</span>
          </a>
          </div>
        </div>
    
        <div className="bottom-nav-item">
          <div className="bottom-nav-link">
             <a href="#">
            <i className="bi bi-share"></i>
            <span>Share</span>
          </a>
          </div>
        </div>
    
      {/* <!-- <div className="bottom-nav-item">
          <div className="bottom-nav-link">
            <i className="bi bi-question"></i>
            <span>Questions</span>
          </div>
        </div> --> */}
    
       
    
      
      </nav>
    </header>
            </div>
        </section>
    
        </>
      )}


    </div>
  )
}

export default ProductGallery
