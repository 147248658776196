import React from 'react'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import { useEffect } from 'react'
import axios from 'axios'
import {GET_DATA} from '../base_url/BaseUrl'
import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'


const Lab = () => {

  const { setSideBar, sidebar } = useContext(AuthContextProvider)
  const [labCategory,setLabCategory] = useState([])
  const navigate = useNavigate();


  const goEnquiry=(i)=>{
    navigate("/labEnquiry",{state:i})

  }

  const Styles = {
    sidebar1:{
      marginLeft:""
    },
    sidebar2:{
      marginLeft:"00px"
    }
  }

  useEffect(() => {
    setSideBar(true)
  },[])

  useEffect(()=>{
    axios.post(GET_DATA,{
      START:"0",
      END:"10000",
      WORD:"",
      GET_DATA:"LabCategory_List",
      ID1:"",
      ID2:"",
      ID3:"",
      STATUS:"",
      START_DATE:"",
      END_DATE:"",
      EXTRA1:"",
      EXTRA2:"",
      EXTRA3:"",
      LANG_ID:"1"
    }).then((responce)=>{
      console.log("LAB CATEGORY API :",responce.data.DATA)
      setLabCategory(responce.data.DATA)
    })
  },[])

  return (
    <div>
      
      <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>

    <section className="section dashboard" id="disease">

    <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="plot-add-button-blk">
              <a href="./labEnquiryList" className="button-style">लॅब चौकशी</a>
            </div>
          </div>
      <div className="row">

        

    <div className="row" id="lab">

    {
      labCategory.map((i,index)=>{
        return(
          <div className="col-lg-4 col-md-4 col-sm-4 col-6">
          <div className="card info-card sales-card">
  
              <div className="card-body">
                <a onClick={()=>goEnquiry(i)}>
                <img src={i.LCAT_IMAGE} width="64" height="64" className="img-fluid" alt=""/>
                <div className="product-name-blk">
                  <div className="text-center">
                <div className="product-title">
                  <h5>{i.LCAT_NAME}</h5>
                </div>
                
              </div>
                <div className="btn_new read-more ">
                 <button type="button"> चौकशी पाठवा  </button> 
                </div>   
              </div>
               </a>
              </div>
          </div>
        </div>
        )
      })
    }



    </div>



      </div>
    </section>

  </main>


  
  <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="./featureDashbord">
        <i className="bi bi-shop"></i>
        <span>Home</span>
      </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="./productGallery">
        <i className="bi bi-handbag"></i>
        <span>Product Gallery</span>
      </a>
      </div>
    </div>
     
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="./lab">
        <i className="bi bi-eyedropper"></i>
        <span>Lab</span>
      </a>
      </div>
    </div>

    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
         <a href="#">
        <i className="bi bi-share"></i>
        <span>Share</span>
      </a>
      </div>
    </div>

  {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}

   

  
  </nav>
</header>
        </div>
    </section>


    </div>
  )
}

export default Lab
