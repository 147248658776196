import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { useLocation } from 'react-router-dom'
import parse from 'html-react-parser';
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import {GET_PRODUCT_IMAGES,GET_PRODUCT_SIZE,CART} from '../base_url/BaseUrl'

const ProductDetails = () => {


  const location = useLocation();
  const [banner, setBanner] = useState([]);
  const [sizes, setSizes] = useState([]);

  console.log(location.state.P_NAME)
  console.log(location.state.P_DESCRIPTION)

  const userId=localStorage.getItem("USER_ID")
  console.log("UserId is  ",userId)

  const { setSideBar, sidebar } = useContext(AuthContextProvider)

  const Styles = {
    sidebar1: {
      marginLeft: ""
    },
    sidebar2: {
      marginLeft: "00px"
    }
  }

  useEffect(() => {
    setSideBar(true)
  }, [])

  {
    parse(location.state.P_DESCRIPTION)
  }

  useEffect(() => {
    axios.post(GET_PRODUCT_IMAGES, {
      USER_ID: "1",
      ACCESS_CODE: "123456",
      PRODUCT_ID: location.state.PRODUCT_ID
    }).then((res) => 
    {
      console.log(res.data)
      setBanner(res.data.DATA)
    })
  }, [])


  useEffect(()=>{
    axios.post(`${GET_PRODUCT_SIZE}?PRODUCT_ID=${location.state.PRODUCT_ID}`,{
      START:"0",
      END:"1000",
      WORD:"None",
      USER_ID:userId,
      TYPE:"All"
    }).then((responce)=>{
      console.log("Product Sizes API : ",responce)
      setSizes(responce.data.DATA)
    })
  },[sizes])


  const minusQty=(i)=>{

    if(i.CART_QTY==1)
    {
      axios.post(CART,{
        USER_ID:userId,
        PRODUCT_ID:location.state.PRODUCT_ID,
        PS_ID:i.PSIZE_ID,
        QTY:"1",
        TASK:"D",
        CART_ID:"1"   
      }).then((responce)=>{
        console.log("Minus QTY API : ",responce.data)

      })
    }
    else
    {
      axios.post(CART,{
        USER_ID:userId,
        PRODUCT_ID:location.state.PRODUCT_ID,
        PS_ID:i.PSIZE_ID,
        QTY:i.CART_QTY-1,
        TASK:"EDIT",
        CART_ID:"1"   
      }).then((responce)=>{
        console.log("Minus QTY API : ",responce.data)
      })
    }


  }
  const plusQty=(i)=>{
    axios.post(CART,{
      USER_ID:userId,
      PRODUCT_ID:location.state.PRODUCT_ID,
      PS_ID:i.PSIZE_ID,
      QTY:i.CART_QTY+1,
      TASK:"EDIT",
      CART_ID:"1"   
    }).then((responce)=>{
      console.log("Plus QTY API : ",responce.data)
      // window.location.reload()
    })

  }
  const addToCart=(i)=>{
    axios.post(CART,{
      USER_ID:userId,
      PRODUCT_ID:location.state.PRODUCT_ID,
      PS_ID:i,
      QTY:"1",
      TASK:"ADD",
      CART_ID:"1"
    }).then((responce)=>{
      console.log("Add To Cart API : ",responce.data)
    })
  }

  return (
    <div>
      <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>
        <section className="section dashboard" id="disease">
          <div className="row">
            <Carousel>
              {banner.map((i) => {
                return (
                  <Carousel.Item>
                    <img
                      className="d-block w-100 h-50"
                      src={i.PI_URL}
                      alt="First slide"
                    />

                  </Carousel.Item>
                )
              })}

            </Carousel>

            <div id="product-description">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card info-card sales-card">

                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="product-name-blk"><h4>{location.state.P_NAME}</h4></div>
                      {/* <div className="product-price">Rs.{location.state.P_PRICE}/ {location.state.P_UNIT}</div> */}
                    </div>
                    
                    <div>
                    <table >

                    {
                      sizes.map((i,index)=>{
                        console.log("i  :",i)
                        return(
                          
                            <tr>
                            <td><div> <h5>{i.SIZE} {i.UNIT} </h5> </div></td>
                      <td><div><h5 className='mx-3' style={{ color: 'green' }}>₹{i.PRICE - i.DISCOUNT}</h5></div></td>
                      <td><div><h5 className='text-decoration-line-through mx-3' style={{color:'gray'}}>₹{i.PRICE}</h5></div></td>
                      
                        <div>
                          {i.CART_STATUS==="No" ? (
                            <td><div className="mob-veiw-btn mx-1 h-30">
                        <a className="btn_new btn-outline-success" onClick={()=>addToCart(i.PSIZE_ID)}>Add</a>
                      </div></td>
                          ) : (
                        <td><div className="quantity">
                        <a onClick={()=>minusQty(i)} className="quantity__minus"><span>-</span></a>
                        
                        <p className='mx-2 my-2'>{i.CART_QTY}</p>
                        <a  className="quantity__plus " onClick={()=>plusQty(i)}><span>+</span></a>
                      </div></td>
                          )}
                           
                      
                            
                        </div>
                        </tr>
                          
                        )
                      })
                    }

</table>

                    </div>
                    <div className="prod-descrip">
                      <h5>Details</h5>
                      <p>{parse(location.state.P_DESCRIPTION)}</p>
                    </div>
                    <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
                      <div className="mob-view-btn">
                        <a href='./cart' className="btn_new btn-success text-center">Go To Cart</a>
                        {/* <!-- <button className="btn btn-success text-center" type="submit">Submit</button> --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>



          </div>
        </section>

      </main>


      <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
          <header className="mob-footer">
            <nav className="bottom-nav">
              <div className="bottom-nav-item active">
                <div className="bottom-nav-link">
                  <a href="feature_dashboard.html">
                    <i className="bi bi-shop"></i>
                    <span>Home</span>
                  </a>
                </div>
              </div>
              <div className="bottom-nav-item">
                <div className="bottom-nav-link">
                  <a href="product-gallery.html">
                    <i className="bi bi-handbag"></i>
                    <span>Product Gallery</span>
                  </a>
                </div>
              </div>

              <div className="bottom-nav-item">
                <div className="bottom-nav-link">
                  <a href="lab.html">
                    <i className="bi bi-eyedropper"></i>
                    <span>Lab</span>
                  </a>
                </div>
              </div>

              <div className="bottom-nav-item">
                <div className="bottom-nav-link">
                  <a href="#">
                    <i className="bi bi-share"></i>
                    <span>Share</span>
                  </a>
                </div>
              </div>

              {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}




            </nav>
          </header>
        </div>
      </section>


    </div>
  )
}

export default ProductDetails
