import React from 'react'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import { useEffect } from 'react'
import {LAB_ENQUIRY_LIST} from '../base_url/BaseUrl'
import axios from 'axios'


const LabEnquiryList = () => {

    const USER_ID=localStorage.getItem("USER_ID")
    const { setSideBar, sidebar } = useContext(AuthContextProvider)

    console.log("Lab Enquiry List : ",USER_ID)

    const Styles = {
        sidebar1:{
          marginLeft:""
        },
        sidebar2:{
          marginLeft:"00px"
        }
      }
    
      useEffect(() => {
        setSideBar(true)
      },[])


      useEffect(()=>{
        axios.post(LAB_ENQUIRY_LIST,{
            START:"0",
            END:"10000",
            WORD:"",
            EXTRA1:"",
            EXTRA2:"",
            EXTRA3:"",
            LANG_ID:"",
            USER_ID:USER_ID

        }).then((responce)=>{
            console.log("Lab Enquiry List : ",responce.data.DATA)
        })
      },[])

  return (
    <div>
        <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>

<section className="section dashboard" id="Dashboard-form">
    <div className="row" id="product-blk">


    <div className="col-lg-6 col-md-6 col-sm-6">
        <div className="card mb-3">
          <div className="row g-0">

            <div className="col-md-12">
              <a href="./plotInfo">
                <div className="card-body">


                  <div className="inner-dashboard-blk">

                    <div className="row">

                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                        <div className="product-name-blk"> <strong>चौकशी नं :</strong>aaaaaa</div>
                      </div>

                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                        <div className="land-process-blk"><strong> Date  : </strong>aaaaaaaaaa</div>
                      </div>

                      

                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                        <div className="land-area-blk"> <strong>Category  :</strong>aaaaaaaa</div>
                      </div>

                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                        <div className="date-blk "><strong>Pending</strong></div>
                      </div>
                     
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                      <div className="land-area-blk"> <strong>Price  : </strong>Rs. 1000</div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                      <div className="land-area-blk"> <strong>Remark  : </strong>User Remark is here</div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                      <div className="land-area-blk"> <strong>Remark  : </strong>Admin Remark is here</div>
                      </div>

                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>


    </div>
      
</section>

</main>

<section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="feature_dashboard.html">
        <i className="bi bi-shop"></i>
        <span>Home</span>
      </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="product-gallery.html">
        <i className="bi bi-handbag"></i>
        <span>Product Gallery</span>
      </a>
      </div>
    </div>
     
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="lab.html">
        <i className="bi bi-eyedropper"></i>
        <span>Lab</span>
      </a>
      </div>
    </div>

    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
         <a href="#">
        <i className="bi bi-share"></i>
        <span>Share</span>
      </a>
      </div>
    </div>

  {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}

   

  
  </nav>
</header>
        </div>
    </section>
    </div>
  )
}

export default LabEnquiryList
