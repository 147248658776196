import axios from 'axios'
import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import login_bg from '../img/login-bg.jpg'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import { useEffect } from 'react'
import {RotatingLines} from 'react-loader-spinner'
import {OTP} from '../base_url/BaseUrl'

const Login = () => {


  const [name,setName]=useState("")
  const [number,setNumber]=useState("")
  const [loading,setLoading]=useState(false)

  const [name1,setName1]=useState(false)
  const[name2,setName2]=useState(false)
  const [number1,setNumber1]=useState(false)
  const[number2,setNumber2]=useState(false)
  const Styles={
    username:{
      color:"red",
      marginBottom:"0"
    },
    username2:{
      display:"none"
    }
    
  }

  const navigate = useNavigate()
  const { setNavBar } = useContext(AuthContextProvider)

  useEffect(() => {
    setNavBar(false)
  },[])


  const callSubmitApi=(e)=>{
e.preventDefault()
const patern1 = /^[A-Za-z]+$/;
const patern2 = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
    if (!name){
      return setName1(true)
    }
    else if (!patern1.test(name)) {
      return setName1(false),
      setName2(true)
      }
    else if(!number){
      // return alert("Please Enter Number")
      return setNumber1(true)
    }
    else if(number.length<10){
      // return alert("Please Enter Valid Number")
      return setNumber1(true)
    }
    else if (!patern2.test(number)) {
      return setNumber1(false),
      setNumber2(true)
      


    }
    else{
    console.log(name)
    console.log(number)

    setLoading(true)

    axios.post(OTP,{
      MOBILE_NUMBER:number
    }).then((responce)=>{
      setLoading(false)
      console.log(responce.data)
      if(responce.data.ResponseCode=="0")
      {
        const staticOtp=responce.data.DATA
        const dynamicOtp=responce.data.ID
        const resCode=responce.data.ResponseCode
        navigate('/otp',{state:{staticOtp,dynamicOtp,name,number}})  
      }
      else
      {
        console.log("OTP Api Failed")
      }
      
    })

  }
  }

  const mobileNumber = (e) => {
    const value = e.target.value;

    if(!Number(value)){
      return setNumber("")
    }
    setNumber(value)

  }

  return (
    <div>



      
      <main>
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container-fluid">
          <div className="row">

            <div className="col-lg-7 col-md-7 d-xs-none">
             <div className="col-xl-7 b-center bg-size">
              <img className="bg-img-cover bg-center" src={login_bg} alt="looginpage"/>
              
              </div>

              
            </div>
            <div className="col-lg-5 col-md-5 d-flex flex-column ">

              <div className="d-flex justify-content-center">
              {/* <!--   <a href="index.html" className="login-logo d-flex align-items-center w-auto">
                  <img src="assets/img/logo.png" alt="">
                  <span className="d-none d-lg-block"><img src="images/logo.png" className="img-fluid" alt="Krushi doot logo"></span>
                </a> --> */}
              </div>

              <div className="card mt-10 mb-3">

                <div className="card-body mr-t5 " id="login-form">

                    
                  

                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                    <p className="text-center small">Welcome back! Log in to your account.</p>
                  </div>

                  <form className="row g-3 needs-validation" noValidate>

                    <div className="col-12">
                      <label htmlFor="yourUsername" className="form-label">Name</label>
                      <div className="input-group has-validation">
                        <input type="text" name="username" onChange={(e)=>{setName(e.target.value)}} className="form-control login-input" id="yourUsername" required placeholder="Enter Your Name"/>
                        <div className="invalid-feedback">Please enter your Name.</div>
                      </div>
                      <div>
                        <p style={name1 ? Styles.username : Styles.username2}>Please enter your name</p>
                        <p style={name2 ? Styles.username : Styles.username2}>Please enter your valid name</p>
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="yourUsername" className="form-label">Mobile Number</label>
                      <div className="input-group has-validation">
                        <input  type="text" maxLength={10} value={number} onChange={(e)=> mobileNumber(e)} name="username" className="form-control login-input" id="yourUsername" required placeholder="Enter Your Mobile Number"/>
                        <div className="invalid-feedback">Please enter Number.</div>
                      </div>
                      <div>
                        <p style={number1 ? Styles.username : Styles.username2}>Please Enter your Number</p>
                        <p style={number2 ? Styles.username : Styles.username2}>Please Enter your Valid  Number</p>
                      </div>
                    </div>

                    {/* <!-- <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label">Password</label>
                      <input type="password" name="password" className="form-control login-input" id="yourPassword" required>
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div> --> */}

                    
                    <div className="col-12">
                      {/* <!-- <button className="btn btn-success text-center" type="submit">Login <i className="icon-right-arrow"></i></button> --> */}
                      <button disabled={loading}  className="btn_new btn-success text-center" onClick={callSubmitApi} style={{borderRadius:"4px", border:"none"}}>
                        {loading && <span style={{position:"relative", left:"35px" , marginLeft:"-20px"}}>
                          <RotatingLines
                          strokeColor="green"
                          strokeWidth="3"
                          animationDuration="0.75"
                          width="30"
                          visible={true}
                          />
                          </span>}
                        Login
                        </button>
                    </div>

                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
  </main>


  <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="login.html">
        <i className="bi bi-box-arrow-in-right"></i>
        <span>Sign In</span>
    </a>
      </div>
    </div>
    {/* <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="register.html">
        <i className="bi bi-box-arrow-right"></i>
        <span>Sign Up</span>
    </a>
      </div>
    </div> */}
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
       <i className="bi bi-share"></i>
        <span>Share App</span>
      </div>
    </div>
    

  </nav>
</header>
        </div>
    </section>




    </div>
  )
}

export default Login


