import React, { useContext, useEffect, useState } from 'react'
import login_bg from '../img/login-bg.jpg'
import { AuthContextProvider } from '../useContext/AuthContext'

const Register = () => {
  const [newChecked, setChecked] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [name1, setName1] = useState(false)
  const [email1, setEmail1] = useState(false)
  const [mobile1, setMobile1] = useState(false)
  const [email2, setEmail2] = useState(false)
  const [mobile2, setMobile2] = useState(false)

  const { setNavBar } = useContext(AuthContextProvider)

  const mobileNumber = (e) => {
    const value = e.target.value;
    if (!Number(value)) {
      return setMobile("")
    } else if (value.lenght < 10) {
      return;
    }

    setMobile(value)
  }

  const signUp = () => {
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const patern2 = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

    if (!name) {
      return setName1(true)
    }else if(!email){
      return setEmail1(true)
    }else if (!mobile){
      return setMobile1(true)
    }else if(!pattern.test(email)){
      return setEmail1(true)
    }else if(!patern2.test(mobile)){
      return setMobile2(true)
    }
  }

  useEffect(() => {
    setNavBar(false)
  }, [])
  return (
    <div>
      <main>
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container-fluid">
            <div className="row">

              <div className="col-lg-7 col-md-7 d-xs-none">
                <div className="col-xl-7 b-center bg-size">
                  <img className="bg-img-cover bg-center" src={login_bg} alt="looginpage" />
                </div>
              </div>
              <div className="col-lg-5 col-md-5 d-flex flex-column ">

                <div className="d-flex justify-content-center">
                  {/* <!--   <a href="index.html" className="login-logo d-flex align-items-center w-auto">
                  <img src="assets/img/logo.png" alt="">
                  <span className="d-none d-lg-block"><img src="images/logo.png" className="img-fluid" alt="Krushi doot logo"></span>
                </a> --> */}
                </div>
                <div className="card mt-5 mb-3">

                  <div className="card-body" id="login-form">

                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Create an Account</h5>
                      <p className="text-center small">Enter your personal details to create account</p>
                    </div>

                    <form className="row g-3 needs-validation" noValidate>
                      <div className="col-12">
                        <label htmlFor="yourName" className="form-label">First Name</label>
                        <input type="text" name="name" className="form-control login-input" value={name} onChange={(e) => setName(e.target.value)} id="yourName" placeholder='Please Enter Your Full Name' required />
                        {name1 && <h5 style={{ color: "red", fontSize: "1rem" }}>Please, enter your name</h5>}
                      </div>

                      <div className="col-12">
                        <label htmlFor="yourEmail" className="form-label">Email</label>
                        <input type="email" name="email" className="form-control login-input" value={email} onChange={(e) => setEmail(e.target.value)} id="yourEmail" placeholder='Please Enter Your Email' required />
                        {email1 && <h5 style={{ color: "red", fontSize: "1rem" }}>Please enter a valid Email</h5>}
                      </div>

                      <div className="col-12">
                        <label htmlFor="yourUsername" className="form-label">Mobile Number</label>
                        <input type="text" name="username" className="form-control login-input" value={mobile} onChange={(e) => mobileNumber(e)} id="yourUsername" placeholder='Please Enter Your Mobile Number' required />
                        {mobile1 && <h5 style={{ color: "red", fontSize: "1rem" }}>Please Enter Your Mobile Number</h5>}
                        {mobile2 && <h5 style={{ color: "red", fontSize: "1rem" }}>Please Enter Your valid Mobile Number</h5>}
                      </div>

                      {/* <div className="col-12 last">
                        <div className="form-check ">
                          <input type='checkbox' style={{ width: "32px", marginLeft: "-12px", cursor: "pointer" }} onChange={() => setChecked(!newChecked)} />
                          <label className="form-check-label" htmlFor="acceptTerms" style={{ color: "green" }}>I agree with terms and condition
                          </label>
                        </div>
                      </div> */}
                      <div className="col-12">
                        <a style={{ cursor: "pointer", borderRadius: "4px" }} className="btn_new btn-success text-center" onClick={() => signUp()}>Sign Up </a>
                        {/* <!-- <button className="btn btn-success text-center" type="submit">Sign Up</button> --> */}
                      </div>

                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      </main>

      {/* <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="login.html">
        <i className="bi bi-box-arrow-in-right"></i>
        <span>Sign In</span>
    </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="register.html">
        <i className="bi bi-box-arrow-right"></i>
        <span>Sign Up</span>
    </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
       <i className="bi bi-share"></i>
        <span>Share App</span>
      </div>
    </div>
    

  </nav>
</header>
        </div>
    </section> */}


    </div>
  )
}

export default Register
