import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AddQuestion from './component/AddQuestion'
import Address from './component/Address'
import DiseaseControl from './component/DiseaseControl'
import Faq from './component/Faq'
import FarmerScoutingReport from './component/FarmerScoutingReport'
import Navbar from './component/Navbar'
import FeatureDashbord from './component/FeatureDashbord'
import LabEnquiry from './component/LabEnquiry'
import Lab from './component/Lab'
import Login from './component/Login'
import ManageFarm from './component/ManageFarm'
import NewPlot from './component/NewPlot'
import Otp from './component/Otp'
import PlotInfo from './component/PlotInfo'
import ProductDetails from './component/ProductDetails'
import ProductGallery from './component/ProductGallery'
import QuestionAnswer from './component/QuestionAnswer'
import Register from './component/Register'
import Schedule from './component/Schedule'
import SelectPlot from './component/SelectPlot'
import PlotRegistrationForm from './component/PlotRegistrationForm'
import Cart from './component/Cart'
import Checkout from './component/Checkout'
import AddPlot from './component/AddPlot'
import LabEnquiryList from './component/LabEnquiryList'
import AddDeliveryLocation from './component/AddDeliveryLocation'
import Weather from './component/Weather'
import KnowledgeHub from './component/KnowledgeHub'
import Demo from './component/Demo'
import Language from './component/Language'


const App = () => {






  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Login/>} />
        <Route path='/language' element={<Language/>} />
        <Route path='/address' element={<Address/>} />
        <Route path='/diseaseControl' element={<DiseaseControl/>} />
        <Route path='/faq' element={<Faq/>} />
        <Route path='/farmerScoutingReport' element={<FarmerScoutingReport/>} />
        <Route path='/featureDashbord' element={<FeatureDashbord/>} />
        <Route path='/labEnquiry' element={<LabEnquiry/>} />
        <Route path='/lab' element={<Lab/>} />
        <Route path='/login' element={<Login/>} />
        <Route path='/manageFarm' element={<ManageFarm/>} />
        <Route path='/newPlot' element={<NewPlot/>} />
        <Route path='/otp' element={<Otp/>} />
        <Route path='/plotInfo' element={<PlotInfo/>} />
        <Route path='/productDetails' element={<ProductDetails/>} />
        <Route path='/productGallery' element={<ProductGallery/>} />
        <Route path='/questionAnswer' element={<QuestionAnswer/>} />
        <Route path='/register' element={<Register/>} />
        <Route path='/schedule' element={<Schedule/>} />
        <Route path='/selectPlot' element={<SelectPlot/>} />
        <Route path='/addQuestion' element={<AddQuestion/>} />
        <Route path='/plotRegistrationForm' element={<PlotRegistrationForm/>} />
        <Route path='/cart' element={<Cart/>} />
        <Route path='/checkout' element={<Checkout/>} />
        <Route path='/addPlot' element={<AddPlot/>} />
        <Route path='/labEnquiryList' element={<LabEnquiryList/>} />
        <Route path='/weather' element={<Weather/>} />
        <Route path='/knowledgeHub' element={<KnowledgeHub/>} />
        
        
        <Route path='/adddeliverylocation' element={<AddDeliveryLocation/>} />
        <Route path='/demo' element={<Demo/>} />





      </Routes>
      
      {/* <Footer/> */}
      
    </BrowserRouter>
  )
}

export default App
