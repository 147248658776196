import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import OTPInput, { ResendOTP } from "otp-input-react";
import { AuthContextProvider } from '../useContext/AuthContext'
import axios from 'axios';
import { USER_REGISTRATION, OTP } from '../base_url/BaseUrl'
import { RotatingLines } from 'react-loader-spinner';
import { animateScroll } from 'react-scroll';



const Otp = () => {

    const [OTP_1, setOTP] = useState("");
    const navigate = useNavigate()
    const [resendotp, setResendOtp] = useState("")
    const [dynamicotp, setDynamicOtp] = useState("")
    const [loading, setLoading] = useState(false)
    // const { handleSubmit } = useForm()
    const location = useLocation()
    const [mobile1, setMobile1] = useState(false)

    console.log("location", location.state.fname);

    useEffect(() => {
        animateScroll.scrollToTop({
            duration:0
        })
    },[location.pathname])


    const { setNavBar } = useContext(AuthContextProvider)

    useEffect(() => {
      setNavBar(false)
    }, [])

   
   

    const Style = {
        usarname: {
            display: "none"
        },
        usarname2: {
            // marginBottom: "14px",
            marginTop: "5px",
            marginLeft: "16px",
            color: "red"
        },
        usarname3: {
            color: "red"
        }
    }

    const resend = () => {
      axios.post(OTP,{
        MOBILE_NUMBER:location.state.number
      }).then((responce)=>{        
        console.log(responce.data)
        if(responce.data.ResponseCode=="0")
      {
        const staticOtp=responce.data.DATA
        const dynamicOtp=responce.data.ID
        const resCode=responce.data.ResponseCode
        setDynamicOtp(dynamicOtp)
      }
      })
    }

    const loginOtp = () => {
    
      if (OTP_1 === location.state.dynamicOtp || OTP_1 === location.state.staticOtp || OTP_1 === dynamicotp) {
        setLoading(true)
        axios.post(USER_REGISTRATION, {
          USER_ID: "1",
          FULL_NAME: location.state.name,
          MOBILE_NO: location.state.number,
          REFERANCE_NO: "",
          ADDRESS: "",
          EMAIL: "",
          LATITUDE: "",
          LONGITUDE: ""
        }).then((response) => {
          setLoading(false)
          if (response.data.ResponseCode == "0") {
            const ADDRESS = response.data.DATA[0].ADDRESS
            const EMAIL = response.data.DATA[0].EMAIL
            const FULL_NAME = response.data.DATA[0].FULL_NAME
            const IS_MEMBERSHIP = response.data.DATA[0].IS_MEMBERSHIP
            const MOBILE_NO = response.data.DATA[0].MOBILE_NO
            const PROFILE_PHOTO = response.data.DATA[0].PROFILE_PHOTO
            const REFERANCE_NO = response.data.DATA[0].REFERANCE_NO
            const REG_DATE = response.data.DATA[0].REG_DATE
            const STATUS = response.data.DATA[0].STATUS
            const TOKEN = response.data.DATA[0].TOKEN
            const USER_ID = response.data.DATA[0].USER_ID
            const WALLET_AMT = response.data.DATA[0].WALLET_AMT
  
            localStorage.setItem("ADDRESS", ADDRESS)
            localStorage.setItem("EMAIL", EMAIL)
            localStorage.setItem("FULL_NAME", FULL_NAME)
            localStorage.setItem("IS_MEMBERSHIP", IS_MEMBERSHIP)
            localStorage.setItem("MOBILE_NO", MOBILE_NO)
            localStorage.setItem("PROFILE_PHOTO", PROFILE_PHOTO)
            localStorage.setItem("REFERANCE_NO", REFERANCE_NO)
            localStorage.setItem("REG_DATE", REG_DATE)
            localStorage.setItem("STATUS", STATUS)
            localStorage.setItem("TOKEN", TOKEN)
            localStorage.setItem("USER_ID", USER_ID)
            localStorage.setItem("WALLET_AMT", WALLET_AMT)
            navigate('/featureDashbord')
            window.location.reload()
          }
  
          console.log(response.data)
        })
  
      }else{
        alert("Please Enter Valid Otp")
      }
    }


    const data = localStorage.getItem("usarname")
    



   



    return (
        <div>
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4" >
                <div className="container-fluid">
                    <div className="row justify-content-center signotp">

                        <div className="col-lg-4 col-md-4">
                            <div className="login-card">
                                    <h4>
                                        ओटीपी पडताळणी
                                    </h4><br />
                                    <input type="hidden" name="csrfmiddlewaretoken" value="" />
                                    <div className="mb-4">
                                        <label>ओटीपी पडताळणी</label><br />
                                        <span>xxxxxxxxx98 वर OTP पाठवला आहे</span><br /><br />

                                        <div className="row signotp">
                                            <div className="col">
                                                <OTPInput className="form-control text-center opt-text new1" style={{ display: "flex", paddingLeft:"26px" }} value={OTP_1} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                                            </div>

                                            <div style={mobile1 ? Style.usarname2 : Style.usarname}>कृपया वैध ओटीपी प्रविष्ट करा</div>

                                        </div>
                                        {/* <!-- <i id="otp_icon" className="fa fa-exclamation-triangle form-error-icon" aria-hidden="true"></i><span id="otp_error" className="error-msg"></span> --> */}
                                    </div>


                                    <div className="mb-3" >
                                        <span className="reset-password-link" style={{ display: "flex", fontSize: "0.8rem" }}>ओटीपी मिळाला नाही का?&nbsp;&nbsp;
                                            {/* <button type="button" className="btn btn-success btn-xs " id="resend_btn">Resend</button> */}
                                           <ResendOTP maxTime={60} className="resend " onResendClick={() => resend()}/>

                                            &nbsp;</span>
                                    </div><br /> 
                                    <div className="mb-3 text-center">
                                        {/* <!-- <button className="btn verify_btn " id="verify_btn" type="submit">Verify </button> --> */}
                                        <a className="btn_new verify_btn" style={{marginRight:"10px", cursor:"pointer"}} disabled={loading} onClick={() =>loginOtp()}>
                                            {loading && <span style={{ marginLeft: "-21px", position: "relative", left: "97px" }}>
                                                <RotatingLines
                                                    strokeColor="green"
                                                    strokeWidth="4"
                                                    animationDuration="0.75"
                                                    width="21"
                                                    visible={true}
                                                /></span>}
                                            ओटीपी पडताळणी करा </a>
                                    </div>
                                    <br /><br /><br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Otp
