import React from 'react'

const AddQuestion = () => {
  return (
    <div>
      
  <main id="main" className="main">

<section className="section dashboard" id="Dashboard-form">
  <div className="row">

    
    
    <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card info-card sales-card">
            <div className="card-body" >
      <div className="row">
       

        <div className="row">
        <div className="col-md-4 col-sm-4 col-lg-4 ">
          <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">श्रेणी</label>
              <select className="form-select minimal" name="" id="">
                <option>श्रेणी निवडा</option>
                <option></option>
                <option></option>
              </select>
             
        </div>
      </div>

       <div className="col-md-4 col-sm-4 col-lg-4 ">
        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">प्रश्न </label>
              <input type="text" className="form-control d-none d-lg-block " id="inputNanme4" placeholder="प्रश्न"/>

              <textarea type="text" className="form-control d-lg-none d-sm-block" id="inputNanme4" placeholder="प्रश्न"></textarea>
              
      </div>
    </div>


    <div className="col-md-4 col-sm-12 col-lg-4">
        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">फोटो  </label>
              <input type="file" className="form-control "/>
      </div>
    </div>
    
     <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
      <a href="question-answer.html" className="btn_new btn-success text-center">Submit</a>
      {/* <!-- <button className="btn btn-success text-center" type="submit">Submit </button> --> */}
      </div>
  </div>


      
    </div>
    </div>
  </div>
</div>


  </div>
</section>

</main>


<section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
    <div className="container">
        <header className="mob-footer">
<nav className="bottom-nav">
<div className="bottom-nav-item active">
  <div className="bottom-nav-link">
    <a href="feature_dashboard.html">
    <i className="bi bi-shop"></i>
    <span>Home</span>
  </a>
  </div>
</div>
<div className="bottom-nav-item">
  <div className="bottom-nav-link">
    <a href="product-gallery.html">
    <i className="bi bi-handbag"></i>
    <span>Product Gallery</span>
  </a>
  </div>
</div>
 
<div className="bottom-nav-item">
  <div className="bottom-nav-link">
    <a href="lab.html">
    <i className="bi bi-eyedropper"></i>
    <span>Lab</span>
  </a>
  </div>
</div>

<div className="bottom-nav-item">
  <div className="bottom-nav-link">
     <a href="#">
    <i className="bi bi-share"></i>
    <span>Share</span>
  </a>
  </div>
</div>

{/* <!-- <div className="bottom-nav-item">
  <div className="bottom-nav-link">
    <i className="bi bi-question"></i>
    <span>Questions</span>
  </div>
</div> --> */}




</nav>
</header>
    </div>
</section>

<footer id="footer" className="footer">
<div className="copyright"> </div>
</footer>

    </div>
  )
}

export default AddQuestion
