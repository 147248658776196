import React, { useState } from 'react'
import mob_soiltestinglab from '../img/mob-soiltestinglab.jpg'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import {LAB_ENQUIRY} from '../base_url/BaseUrl'

const LabEnquiry = () => {

  const [note,setNote] = useState("")
  const { setSideBar, sidebar } = useContext(AuthContextProvider)
  const location = useLocation();
  console.log("LCAT_NAME :",location.state.AMOUNT)
  console.log("LCAT_NAME :",location.state.LCAT_ID)
  console.log("LCAT_NAME :",location.state.LCAT_IMAGE)
  console.log("LCAT_NAME :",location.state.LCAT_NAME)
  console.log("LCAT_NAME :",location.state.VIDEO_URL)


  const FULL_NAME=localStorage.getItem("FULL_NAME")
  const MOBILE_NO=localStorage.getItem("MOBILE_NO")
  const USER_ID=localStorage.getItem("USER_ID")

  const Styles = {
    sidebar1:{
      marginLeft:""
    },
    sidebar2:{
      marginLeft:"00px"
    }
  }

  useEffect(() => {
    setSideBar(true)
  },[])


  const callPostEnquiry=(e)=>{
    axios.post(LAB_ENQUIRY,{
      USER_ID:USER_ID,
      LCAT_ID:location.state.LCAT_ID,
      REMARK:note,
      EXTRA1:location.state.AMOUNT,
      EXTRA2:"",
      EXTRA3:"",
      TASK:"ADD",
      FULL_NAME:FULL_NAME,
      MOBILE_NO:MOBILE_NO,
      ADDRESS:"",
      PINCODE:""
    }).then((responce)=>{
      if(responce.data.ResponseCode=="0")
      {
        alert("Success")
      }
      else
      {
        alert("Something went wrong please try again...")
      }
      console.log("ENQUIRY_API :",responce.data.DATA)
    })
  }


  return (
    <div>
      
      <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>

<section className="section dashboard" id="Dashboard-form">
  <div className="row">

    <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card info-card sales-card">
            <div className="card-body" >
      <div className="row">
       

        <div className="row">
        <div className="col-md-2 col-sm-2 col-lg-2 ">


          <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">श्रेणी</label>
                <h5>{location.state.LCAT_NAME}</h5>
        </div>

      

      </div>
        <div className="col-md-10 col-sm-10 col-lg-10">


        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">रक्क्म</label>
                <h5>Rs.{location.state.AMOUNT}/-</h5>
        </div>

       

      </div>




  
         <div className="col-md-12 col-sm-12 col-lg-12 d-sm-block d-lg-none">
        <div className="form-group namuna-">
          <label htmlFor="inputNanme4" className="form-label">नमुना कसा गोळा  करावा ? </label>
          <div className="soil-testing-img">
          <img src={mob_soiltestinglab} className="img-fluid" alt=""/>
        </div>
        </div>
      </div>



       <div className="col-md-4 col-sm-4 col-lg-4 ">
        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">पूर्ण नाव  </label>
              <input type="text" className="form-control" id="inputNanme4" placeholder="पूर्ण नाव  " value={FULL_NAME}/>
          </div>
        </div>


       <div className="col-md-4 col-sm-4 col-lg-4 ">
        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">मोबाईल नंबर   </label>
              <input type="text"  className="form-control" id="inputNanme4" placeholder="मोबाईल नंबर  " value={MOBILE_NO}/>
          </div>
        </div>

 

         <div className="col-md-4 col-sm-4 col-lg-4 ">
        <div className="form-group">
              <label htmlFor="inputNanme4" className="form-label">टिपणी  </label>
              <input type="text" className="form-control d-none d-lg-block " id="inputNanme4" placeholder="टिपणी "/>

              <textarea type="text" onChange={(e)=>{setNote(e.target.value)}} className="form-control d-lg-none d-sm-block" id="inputNanme4" placeholder="टिपणी "></textarea>
              
      </div>
    </div>

    <div className="col-md-12 col-sm-12 col-lg-12 d-none d-sm-none d-lg-block">
        <div className="form-group namuna-">
          <label htmlFor="inputNanme4" className="form-label">नमुना कसा गोळा करावा ?</label>
          <div className="soil-testing-img text-center">
            <a href={`https://www.youtube.com/watch?v=${location.state.VIDEO_URL}`} target="_blank">
              <img src={`http://img.youtube.com/vi/${location.state.VIDEO_URL}/0.jpg`} className="img-fluid" alt="" />
            </a>
          </div>
        </div>
      </div>




    
    
     <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
      <a className="btn_new btn-success text-center" onClick={callPostEnquiry}>Submit</a>
      </div>
  </div>


      
    </div>
    </div>
  </div>
</div>

  </div>
</section>

</main>

<section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="feature_dashboard.html">
        <i className="bi bi-shop"></i>
        <span>Home</span>
      </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="product-gallery.html">
        <i className="bi bi-handbag"></i>
        <span>Product Gallery</span>
      </a>
      </div>
    </div>
     
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="lab.html">
        <i className="bi bi-eyedropper"></i>
        <span>Lab</span>
      </a>
      </div>
    </div>

    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
         <a href="#">
        <i className="bi bi-share"></i>
        <span>Share</span>
      </a>
      </div>
    </div>

  {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}

   

  
  </nav>
</header>
        </div>
    </section>


    </div>
  )
}

export default LabEnquiry
