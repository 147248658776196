import React,{useState} from 'react'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import { useEffect } from 'react'
import axios from 'axios'
import {GET_DATA} from '../base_url/BaseUrl'
import { Navigate, useNavigate } from 'react-router-dom'


const SelectPlot = () => {


  const { setSideBar, sidebar } = useContext(AuthContextProvider)
  const [croplist,setCropList] = useState([])

  const navigate = useNavigate();

  const Styles = {
    sidebar1:{
      marginLeft:""
    },
    sidebar2:{
      marginLeft:"00px"
    }
  }

  useEffect(() => {
    setSideBar(true)
  },[])


  useEffect(()=>{
    axios.post(GET_DATA,{
      START:"0",
      END:"10000",
      WORD:"",
      GET_DATA:"Crop_List",
      ID1:"",
      ID2:"",
      ID3:"",
      STATUS:"",
      START_DATE:"",
      END_DATE:"",
      EXTRA1:"",
      EXTRA2:"",
      EXTRA3:"",
      LANG_ID:"1"
    }).then((responce)=>{
      console.log("CropListApi :",responce.data.DATA)
      setCropList(responce.data.DATA)
    })
  },[])


  const goToAddPlot=(i)=>{
    localStorage.setItem("CROP_ID",i.CROP_ID)
    navigate("/addPlot",{state:i})


  }

  return (
    <div>
      
      <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>

      <div className="row" id="product-tab">
        <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card-body">
              <div className="row">
        {
          croplist.map((i,index)=>{
            return(

                <div className="col-lg-3 col-md-3 col-sm-3 col-12 my-1 mx-0.5" onClick={()=>goToAddPlot(i)}>
                  <div className="row">
                    <div className="select-plot-outer-blk">
                    
                     <div className="col-lg-6 col-md-6 col-sm-6 col-4" >
                      <div className="product-ouetr-blk">
                        <div className="select-plot-icon-circle select-plot-mr-t3">
                          <img src={i.CROP_IMAGE} className="img-fluid" style={{height:"60px"}}/>
                        </div>
                      </div>
                  
                  </div> 
                   <div className="col-lg-6 col-md-6 col-sm-6 col-9" >
                      <div className="plot-name">{i.CROP_NAME}</div>
                   </div>
                  </div>
                </div>
                </div>



          
            )
          })
        }
    </div>
          </div>

      </div>
    </div>

  </main>


  <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="./featureDashbord">
        <i className="bi bi-shop"></i>
        <span>Home</span>
      </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="./productGallery">
        <i className="bi bi-handbag"></i>
        <span>Product Gallery</span>
      </a>
      </div>
    </div>
     
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="./lab">
        <i className="bi bi-eyedropper"></i>
        <span>Lab</span>
      </a>
      </div>
    </div>

    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
         <a href="#">
        <i className="bi bi-share"></i>
        <span>Share</span>
      </a>
      </div>
    </div>

  {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}

   

  
  </nav>
</header>
        </div>
    </section>



    </div>
  )
}

export default SelectPlot
