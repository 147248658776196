import axios from 'axios'
import React,{useState} from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import { useNavigate } from 'react-router-dom'
import {CART} from '../base_url/BaseUrl'






const Cart = () => {

    const [products, setProducts] = useState([]);
    const { setSideBar, sidebar } = useContext(AuthContextProvider)
    const USER_ID=localStorage.getItem("USER_ID")
    const totalProducts=products.length;
    const navigate = useNavigate()

    const Styles = {
        sidebar1: {
          marginLeft: ""
        },
        sidebar2: {
          marginLeft: "00px"
        }
      }
    
      useEffect(() => {
        setSideBar(true)
      }, [])


      useEffect(()=>{
        axios.post(`${CART}?USER_ID=${USER_ID}&TYPE=all`,{
            START:"0",
            END:"10000",
            WORD:"None",
            USER_ID:USER_ID,
            TYPE:"1"
        }).then((responce)=>{
            console.log(responce.data)
            setProducts(responce.data.DATA)
        })
    },[products])
    



    const minusQty=(i)=>{

        if(i.QTY==1)
        {
          axios.post(CART,{
            USER_ID:USER_ID,
            PRODUCT_ID:i.PRODUCT_ID,
            PS_ID:i.PS_ID,
            QTY:"1",
            TASK:"D",
            CART_ID:"1"   
          }).then((responce)=>{
            console.log("Minus QTY API : ",responce.data)
    
          })
        }
        else
        {
          axios.post(CART,{
            USER_ID:USER_ID,
            PRODUCT_ID:i.PRODUCT_ID,
            PS_ID:i.PS_ID,
            QTY:i.QTY-1,
            TASK:"EDIT",
            CART_ID:"1"   
          }).then((responce)=>{
            console.log("Minus QTY API : ",responce.data)
          })
        }
    
    
      }




      const plusQty=(i)=>{
        axios.post(CART,{
          USER_ID:USER_ID,
          PRODUCT_ID:i.PRODUCT_ID,
          PS_ID:i.PS_ID,
          QTY:i.QTY+1,
          TASK:"EDIT",
          CART_ID:"1"   
        }).then((responce)=>{
          console.log("Plus QTY API : ",responce.data)
          // window.location.reload()
        })
    
      }




      
    const deleteProduct=(i)=>{

       
          axios.post(CART,{
            USER_ID:USER_ID,
            PRODUCT_ID:i.PRODUCT_ID,
            PS_ID:i.PS_ID,
            QTY:"1",
            TASK:"D",
            CART_ID:"1"   
          }).then((responce)=>{
            console.log("Minus QTY API : ",responce.data)
    
          })
        }
    
      
        const goToCheckout=()=>{
          navigate('/checkout')
        }

  return (
    <div>
        <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>
      <section className="h-100 h-custom" >
  <div className="container  h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-12">
        <div className="card card-registration card-registration-2" style={{borderRadius: "15px"}}>
          <div className="card-body p-0">
            <div className="row g-0">
              <div className="col-lg-12">
                <div className="p-5">
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <h1 className="fw-bold mb-0 text-black">My Cart</h1>
                    <h6 class="mb-0 text-muted">{totalProducts} items</h6>
                  </div>


                      
                  

                      <hr className="my-2"/>
                

                {
                    products.map((i,index)=>{
                            return(
                            
                                <div key={index}>
                                     <div className="row mb-4 d-flex justify-content-between align-items-center">
                    <div className="col-md-2 col-lg-2 col-xl-2">
                      <img
                        src={i.P_IMAGE}
                        className="img-fluid rounded-3" alt=""/>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-3">
                      <h6 className="text-black mb-0">{i.P_NAME}</h6>
                      <h6 className="text-muted">{i.P_SIZE}{i.P_UNIT}</h6>
                    </div>

                    <div className="col-md-3 col-lg-2 col-xl-2 d-flex">
                      <h6 className="mb-0" style={{color:"green"}}> <strong>₹{i.P_PRICE - i.P_DESC}</strong> </h6>
                      {/* <div className='vl mx-2'></div> */}
                      <h6 className="mb-0 text-decoration-line-through mx-4">₹{i.P_PRICE}</h6>


                    </div>

                    <div className="col-md-3 col-lg-3 col-xl-2 d-flex">
                    <div className="quantity">
                        <a onClick={()=>minusQty(i)}  className="quantity__minus"><span>-</span></a>
                        <p className='mx-2 my-2'>{i.QTY}</p>
                        <a  className="quantity__plus " onClick={()=>plusQty(i)}><span>+</span></a>
                      </div>
                    </div>

                    <div className="col-md-3 col-lg-1 col-xl-1 text-end">
                    <i className='bi bi-trash' onClick={()=>deleteProduct(i)} style={{fontSize: "1.5rem", color:"rgb(255, 0, 0)"}}></i>
                    </div>
                  </div>

                  <hr className="my-2"/>
                                </div>

                            )
                    })
                }

        <div className="responsive_checkout_btn row d-lg-none  d-sm-block d-xs-block">
            <div className="checkout_btn">
                <p>2 Item</p>
                <p>| Total Amount</p>
                <p>123</p>
                <p className="checkout">Check Out <i className="bi bi-chevron-right"></i></p>
            </div>
        </div>

                <button type="button" onClick={goToCheckout} className="btn_new btn-outline-success text-end">Proceed To Checkout</button>


                
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</main>
    </div>
  )
}

export default Cart
