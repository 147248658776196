import React, { useState } from 'react'
import farmer from '../img/farmer.png'
import lab from '../img/lab.png'
import nursary from '../img/nursary.png'
import book from '../img/book.png'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import { useEffect } from 'react'
import { PLOT_LIST } from '../base_url/BaseUrl'
import { GET_DATA } from '../base_url/BaseUrl'
import {WEATHER_URL} from '../base_url/BaseUrl'
import axios from 'axios'
import { AuthContext } from '../useContext/AuthContext'
import Carousel from 'react-bootstrap/Carousel'


import thermometer from '../img/thermometer_1.png'
import windimg from '../img/wind.png'
import humidityimg from '../img/humidity.png'
import pressureimg from '../img/pressure.png'
import { useNavigate } from 'react-router-dom'

import clear_sky from '../img/first_weather.webp'
import few_clouds from '../img/twelve_weather.webp'
import scattered_clouds from '../img/eighth_weather.webp'
import broken_cloud from '../img/fourteen_weather.webp'
import overcast_clouds from '../img/third_weather.webp'
import light_rain from '../img/sixth_weather.webp'





const FeatureDashbord = () => {
  const navigate = useNavigate()
  const { setSideBar, sidebar } = useContext(AuthContextProvider)
  const [plots, setPlots] = useState([])
  // const[banner,setBanner]=useEffect()
  const [new1, setNew] = useState([])
  const [wind, setWind] = useState([])
  const [temp, setTemp] = useState([])
  const [humidity, setHumidity] = useState([])
  const [pressure, setPressure] = useState([])
  const [mtemp, setMtemp] = useState([])
  const [new1w, setNeww] = useState([])
  // const { banner } = useSelector((state) => state.banner)
  const [banner, setBanner] = useState([])


  
  const Styles = {
    sidebar1:
    {
      marginLeft: ""
    },
    sidebar2:
    {
      marginLeft: "00px"
    }
  }

  useEffect(() => {
    setSideBar(true)
  }, [])

  useEffect(() => {
    axios.post(PLOT_LIST, {
      START: "0",     
      END: "10000",
      WORD: "",
      EXTRA1: "",
      EXTRA2: "",
      EXTRA3: "",
      USER_ID: "",
      LANG_ID: "1"        
    }).then((responce) => {
      console.log("Plot List API :", responce.data.DATA)
      setPlots(responce.data.DATA)  
    })
  }, [])


  useEffect(() => {
    axios.post(GET_DATA,
      {
        "START": "0",
        "END": "1000",
        "WORD": "",
        "GET_DATA": "Banner_List",
        "ID1": "",
        "ID2": "",
        "ID3": "",
        "STATUS": "",
        "START_DATE": "",
        "END_DATE": "",
        "EXTRA1": "",
        "EXTRA2": "",
        "EXTRA3": "",
        "LANG_ID": "1"
      }
    ).then((responce) => {
      
      console.log("banner list api", responce.data.DATA)
      setBanner(responce.data.DATA)
    })
    
  },[])

  


  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      // console.log("position.coords.latitude", position.coords.latitude);
      // console.log("position.coords.longitude", position.coords.longitude);
      axios.get(WEATHER_URL)
        .then((res) => {
          const data = res.data.list[0]
          console.log("data", data);
          // console.log("data", data.weather[0].description);

          // console.log("data",data.wind.speed);
          // console.log("data",data.main.temp_min);
          // console.log("data",data.main.humidity);
          // console.log("data",data.main.pressure);
          // console.log("data",data.main.temp_max);
          setWind(data.wind.speed);
          setTemp(data.main.temp_min);
          setHumidity(data.main.humidity);
          setPressure(data.main.pressure);
          setMtemp(data.main.temp_max);

          setNew(data.weather[0]);
          setNeww(data.weather[0]);
          // res.data.list.forEach((i)=>{
          //     i.weather.forEach((i)=>{
          //     console.log("weatherdata",i.description);                
          //     });
          //    })

        })
    })
  }, [])





  return (
    <div>

      <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}
      >

        <section className="section" id="feature_dashboard">
          <div className="row">

            <div className="col-lg-6 col-md-6" style={{ borderRadius: "10px", height: "200px" }}>
              <div className="card">
                <div className="card-body pd-0">

                  <Carousel>
                    {banner.map((i) => {
                      return (
                        <Carousel.Item>
                          <img
                            className="d-block w-100 h-50"
                            src={i.BANNER_IMAGE}
                            alt="First slide"
                          />

                        </Carousel.Item>
                      )
                    })}

                  </Carousel>
                </div>
              </div>
            </div>





            {/* 
  <div className="col-lg-6 col-md-6">
              <div className="card">
                <div className="card-body pd-0">
                  <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src={banner1} className="d-block w-100" alt="..." />
                      </div>
                      <div className="carousel-item">
                        <img src={banner2} className="d-block w-100" alt="..." />
                      </div>
                      <div className="carousel-item">
                        <img src={banner3} className="d-block w-100" alt="..." />
                      </div>
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                    </button>

                  </div>
                </div>
              </div>
            </div> */}

   <div class="col-lg-6 col-md-6" id="pd-20">

   <div id="clouds" class="row weather-block service-list" style={{ height: "200px", cursor: "pointer" }} onClick={() => navigate('/weather')}>
    <div class="col-12 " style={{ padding: "5px 0px 0px 0px" }}>
        <div class="row" style={{ height: "15px", paddingRight: "5px", paddingLeft: "5px", borderRadius: "10px", marginBottom: "10px" }}>
            <div class="col-6 p-0" style={{ textAline: "left", margin: "auto" }}>
                <h6 style={{ marginLeft: "34px", marginTop: "76px" }}><img  style={{height: "19px",marginLeft: "-21px",marginTop: "15px", paddingRight:"4px"}} src={thermometer}/>किमान</h6>
                <h6 style={{ marginLeft: "35px", fontWeight: "bold",marginTop: "-18px" }}>{(temp - 273.15).toFixed(2)} &#176;C</h6>
            </div>
            <div class="col-6 p-0" style={{ textAlign: "right", paddingRight: "10px!important", margin: "auto" }}>
                <h6 className='paha'>सर्व पहा   </h6>
            </div> 
        </div>
        <div class="row" style={{ paddingRight: "10px" }}>
            <div class="col-4 p-0" style={{ textAlign: "left" }}>
                {/* <img style={{ position: "absolute", top: "-12px", marginLeft: "67px", marginTop: "67px" }} class="weather-icon" src="./images/sun.webp"/> */}


                {/* <img style={{ marginLeft: "33px", marginTop: "5px", height: "64px", width: "77px"}} className='img-fluid img-weather' src={few_clouds} alt="" /> */}
           
                      {new1.description === "broken clouds" && <img className='img-fluid img-weather '  src={broken_cloud} alt="" />}
                      {new1.description === "overcast clouds" && <img className='img-fluid img-weather '  src={overcast_clouds} alt="" />}
                      {new1.description === "light rain" && <img className='img-fluid img-weather ' src={light_rain} alt="" />}
                      {new1.description === "clear sky" && <img className='img-fluid img-weather '  src={clear_sky} alt="" />}
                      {new1.description === "scattered clouds" && <img className='img-fluid img-weather '  src={scattered_clouds} alt="" />}
                      {new1.description === "few clouds" && <img className='img-fluid img-weather'  src={few_clouds} alt="" />}

                                            </div>
            <div class="col-3 p-0 text-center">
                <h4 style={{ color: "rgb(216 221 229)", fontWeight: "normal", position: "absolute", left: "32%" }}>{(mtemp - 273.15).toFixed(2)} &#176; c</h4>
</div>
<div class="col-3 p-0 text-center">
                <h4 style={{ color: "rgb(216 221 229)", fontWeight: "normal", position: "absolute", left: "32%",marginTop: "21px" }}>{new1w.description}</h4>

            </div>

            <div class="col-5 p-0" style={{ textAlign: "left", paddingRight: "10px!important" }}>
                <h6 ><img  style={{height: "19px",marginLeft: "-21px",marginTop:"20px", paddingRight:"5px"}} src={humidityimg}/>आर्द्रता  </h6>
                <h6 style={{ marginTop: "29px", fontWeight: "bold",marginLeft:"10px" }}>{humidity}%</h6>
            </div>
            <div class="col-5 p-0" style={{ textAlign: "left", paddingRight: "10px!important" }}>
                <h6 style={{ marginTop: "49px",marginLeft:"10px" }}><img  style={{height: "19px",marginLeft: "-29px", paddingRight:"10px",marginTop:"14px"}} src={pressureimg}/>दबाव   </h6>
                <h6 style={{ marginTop: "74px", fontWeight: "bold",marginLeft:"10px" }}>{pressure} hpa</h6>
            </div>
        </div>
        <div class="row" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <div class="col-6 p-0">
                <h6 style={{ position: "relative", marginTop: "30px", marginLeft: "30px" }}><img  style={{height: "19px",marginLeft: "-28px",marginTop:"17px", paddingRight:"7px"}} src={windimg}/>वाऱ्याचा वेग  </h6>
                <h6 style={{ marginLeft: "31px", fontWeight: "bold",marginTop:"-18px" }} >{wind} mph </h6>
            </div>

        </div>
    </div>
</div>
</div>

            

          </div>

        </section>

        <div className="row" id="product-tab">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 col-md-3 co-sm-3 col-3" >
                    <a href="./manageFarm">
                      <div className="product-ouetr-blk">
                        <div className="farm-prodct-icon-circle">
                          <img src={farmer} className="img-fluid" width="48px" />
                        </div>
                        My Plots
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-3 co-sm-3 col-3">
                    <a href="./lab">
                      <div className="product-ouetr-blk">
                        <div className="farm-prodct-icon-circle">
                          <img src={lab} className="img-fluid" width="48px" />
                        </div>
                        Lab
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-3 co-sm-3 col-3">
                    <a href="./productGallery">
                      <div className="product-ouetr-blk">
                        <div className="farm-prodct-icon-circle">
                          <img src={nursary} className="img-fluid" width="48px" />
                        </div>
                        Mart
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-3 co-sm-3 col-3">
                    <a href="./knowledgeHub">
                      <div className="product-ouetr-blk">
                        <div className="farm-prodct-icon-circle">
                          <img src={book} className="img-fluid" width="48px" />
                        </div>
                        Knowledge Hub
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="row" id="product-blk">

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="plot-add-button-blk">
              <a href="./selectPlot" className="button-style">प्लॉट  नोंदणी करा</a>
            </div>
          </div>

          {
            plots.map((i, index) => {
              return (
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="card mb-3">
                    <div className="row g-0">

                      <div className="col-md-12">
                        <a href="./plotInfo">
                          <div className="card-body">


                            <div className="inner-dashboard-blk">

                              <div className="row">

                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                                  <div className="product-name-blk"> <strong>पिकाचे नाव :</strong> {i.CROP_NAME}</div>
                                </div>

                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                                  <div className="date-blk "><strong>नोंदणी तारीख :</strong> {i.REG_DATE}</div>
                                </div>

                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                                  <div className="land-area-blk"> <strong>एकर  :</strong> {i.PLOT_AREA}</div>
                                </div>

                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                                  <div className="land-process-blk"><strong> स्थिती  : </strong> {i.STATUS}</div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                                  <div className="read-btn">अधिक माहिती </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }




        </div>






      </main>

      <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
          <header className="mob-footer">
            <nav className="bottom-nav">
              <div className="bottom-nav-item active">
                <div className="bottom-nav-link">
                  <a href="./featureDashbord">
                    <i className="bi bi-shop"></i>
                    <span>Home</span>
                  </a>
                </div>
              </div>
              <div className="bottom-nav-item">
                <div className="bottom-nav-link">
                  <a href="./productGallery">
                    <i className="bi bi-handbag"></i>
                    <span>Mart</span>
                  </a>
                </div>
              </div>

              <div className="bottom-nav-item">
                <div className="bottom-nav-link">
                  <a href="./lab">
                    <i className="bi bi-eyedropper"></i>
                    <span>Lab</span>
                  </a>
                </div>
              </div>

              <div className="bottom-nav-item">
                <div className="bottom-nav-link">
                  <a href="#">
                    <i className="bi bi-share"></i>
                    <span>Share</span>
                  </a>
                </div>
              </div>

              {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}




            </nav>
          </header>
        </div>
      </section>



    </div>
  )
}

export default FeatureDashbord
