import React,{useState} from 'react'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import { useEffect } from 'react'
import axios from 'axios'
import {PLOT_LIST} from '../base_url/BaseUrl'
import { RotatingLines } from 'react-loader-spinner'

const ManageFarm = () => {


  const { setSideBar, sidebar } = useContext(AuthContextProvider)
  const [plots,setPlots] = useState([])
  const [loading,setLoading]=useState(false)

  const Styles = {
    sidebar1:{
      marginLeft:""
    },
    sidebar2:{
      marginLeft:"00px"
    }
  }

  useEffect(() => {
    setSideBar(true)
  },[])


  useEffect(()=>{
    setLoading(true)

axios.post(PLOT_LIST,{
  START:"0",
  END:"10000",
  WORD:"",
  EXTRA1:"",
  EXTRA2:"",
  EXTRA3:"",
  USER_ID:"",
  LANG_ID:"1"
 }).then((responce)=>{
  console.log("Plot List API :",responce.data.DATA)
  setPlots(responce.data.DATA)
  setLoading(false)

 })
  },[])

  return (
    <div>

      {loading ? (
        <span style={{marginLeft:"800px" , position:"relative", top:"300px"}}>
        <RotatingLines
        strokeColor="grey"
        strokeWidth="3"
        animationDuration="1.5"
        width="96"
        visible={true}
      />
      </span>
      ):(
        <>
              
      <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>

<section className="section" id="feature_dashboard">





  <div className="row" id="product-blk">
       
  <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="plot-add-button-blk">
              <a href="./selectPlot" className="button-style">प्लॉट  नोंदणी करा</a>
            </div>
          </div>
  {
      plots.map((i,index)=>{
        return(
      <div className="col-lg-6 col-md-6 col-sm-6">
        <div className="card mb-3">
          <div className="row g-0">

            <div className="col-md-12">
              <a href="./plotInfo">
                <div className="card-body">


                  <div className="inner-dashboard-blk">

                    <div className="row">

                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                        <div className="product-name-blk"> <strong>पिकाचे नाव :</strong> {i.CROP_NAME}</div>
                      </div>

                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                        <div className="date-blk "><strong>नोंदणी तारीख :</strong> {i.REG_DATE}</div>
                      </div>

                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                        <div className="land-area-blk"> <strong>एकर  :</strong> {i.PLOT_AREA}</div>
                      </div>

                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6">
                        <div className="land-process-blk"><strong> स्थिती  : </strong> {i.STATUS}</div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                        <div className="read-btn">अधिक माहिती </div>
                      </div>

                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
        )
      })
    }

  </div>
</section>

</main>


<section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
    <div className="container">
        <header className="mob-footer">
<nav className="bottom-nav">
<div className="bottom-nav-item active">
  <div className="bottom-nav-link">
    <a href="feature_dashboard.html">
    <i className="bi bi-shop"></i>
    <span>Home</span>
  </a>
  </div>
</div>
<div className="bottom-nav-item">
  <div className="bottom-nav-link">
    <a href="product-gallery.html">
    <i className="bi bi-handbag"></i>
    <span>Product Gallery</span>
  </a>
  </div>
</div>
 
<div className="bottom-nav-item">
  <div className="bottom-nav-link">
    <a href="lab.html">
    <i className="bi bi-eyedropper"></i>
    <span>Lab</span>
  </a>
  </div>
</div>

<div className="bottom-nav-item">
  <div className="bottom-nav-link">
     <a href="#">
    <i className="bi bi-share"></i>
    <span>Share</span>
  </a>
  </div>
</div>

{/* <!-- <div className="bottom-nav-item">
  <div className="bottom-nav-link">
    <i className="bi bi-question"></i>
    <span>Questions</span>
  </div>
</div> --> */}




</nav>
</header>
    </div>
</section>

        </>
      )
      }

    </div>
  )
}

export default ManageFarm
