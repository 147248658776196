import React from 'react'

const Schedule = () => {
  return (
    <div>
      
      <main id="main" className="main">

    <section className="section dashboard" id="schedule">
      <div className="row">

        <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card info-card sales-card">
                <div className="card-body" >
                    <div className="schedule-outer-blk">
                      <div className="row">
                        <div className="col-md-4 col-sm-4 col-4">
                          <div className="icon"></div>
                          <div className="card-title">फवारणी </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-4">
                          <div className="icon"></div>
                          <div className="card-title">खते </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-4">
                          <div className="icon"></div>
                          <div className="card-title">सामान्य </div>
                        </div>
                      </div>
                    </div>
                </div>
          </div>
      </div>
      </div>
 <div className="col-lg-12 col-md-12 col-sm-12">
      <div className="card" id="farming-tipg-blk">
            <div className="card-header">1-5 Days Work</div>
            <div className="card-body">
              <h5 className="card-title">Tomato Farming Tips</h5>
              Ut in ea error laudantium quas omnis officia. Sit sed praesentium voluptas. Corrupti inventore consequatur nisi necessitatibus modi consequuntur soluta id. Enim autem est esse natus assumenda. Non sunt dignissimos officiis expedita.
            </div>
        </div>
    </div>
  </section>

  </main>

  <section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="feature_dashboard.html">
        <i className="bi bi-shop"></i>
        <span>Home</span>
      </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="product-gallery.html">
        <i className="bi bi-handbag"></i>
        <span>Product Gallery</span>
      </a>
      </div>
    </div>
     
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="lab.html">
        <i className="bi bi-eyedropper"></i>
        <span>Lab</span>
      </a>
      </div>
    </div>

    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
         <a href="#">
        <i className="bi bi-share"></i>
        <span>Share</span>
      </a>
      </div>
    </div>

  {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}

   

  
  </nav>
</header>
        </div>
    </section>



    </div>
  )
}

export default Schedule
