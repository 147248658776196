import React from 'react'
import timetable from '../img/timetable.png'
import faq from '../img/faq.png'
import question from '../img/question.png'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import { useEffect } from 'react'


const PlotInfo = () => {

  const { setSideBar, sidebar } = useContext(AuthContextProvider)

  const Styles = {
    sidebar1:{
      marginLeft:""
    },
    sidebar2:{
      marginLeft:"00px"
    }
  }

  useEffect(() => {
    setSideBar(true)
  },[])


  return (
    <div>
      
      <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>

        

<section className="section dashboard" id="plot-info">
  <div className="row">

    <div className="col-lg-3 col-md-3 col-sm-3 col-6">
      <a href="schedule.html">
          <div className="card info-card sales-card">
            <div className="card-body" >
                <div className="icon-circle"><img src={timetable} className="img-fluid"/></div>
                <div className="card-title">वेळापत्रक</div>
          </div>
     </div>
   </a>
  </div>
  <div className="col-lg-3 col-md-3 col-sm-3 col-6">
    <a href="question-answer.html">
          <div className="card info-card sales-card">
            <div className="card-body">
                <div className="icon-circle"><img src={faq} className="img-fluid"/></div>
                <div className="card-title">प्रश्न विचारा</div>
          </div>
     </div>
   </a>
  </div>
  <div className="col-lg-3 col-md-3 col-sm-3 col-6">
    <a href="faq.html">
          <div className="card info-card sales-card">
            <div className="card-body" >
                <div className="icon-circle"><img src={question} className="img-fluid"/></div>
                <div className="card-title">प्रश्न संच </div>
          </div>
     </div>
   </a>
  </div>
  <div className="col-lg-3 col-md-3 col-sm-3 col-6">
    <a href="disease-control.html">
          <div className="card info-card sales-card">
            <div className="card-body" >
                <div className="icon-circle"><img src={faq} className="img-fluid"/></div>
                <div className="card-title">रोग नियंत्रण </div>
          </div>
     </div>
   </a>
  </div>


  </div>
</section>

{/* <section id="farm-dashboard">

  <div className="row">
    <div className="col-xxl-12 col-md-12">
          <div className="card info-card sales-card">

            <div className="card-body">
              <a href="farmer_scouting_report.html">
              <div className="d-flex align-items-center">
                <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                   <div className="card-icon d-flex align-items-center justify-content-center">
                 <img src={plant} width="48px" className="img-fluid"/>
                </div>
                </div>
                 <div className="col-lg-10 col-md-10 col-sm-8 col-8">
                   <div className="farm-dashboard-title ps-3">
                  <h5>Send Crop Problem</h5>
                </div>
                 </div>
              </div>
            </a>
            </div>

          </div>
        </div>
        <div className="col-xxl-12 col-md-12">
          <div className="card info-card sales-card">

           <div className="card-body">
             <a href="manage_farm.html">
              <div className="d-flex align-items-center">
                <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                   <div className="card-icon d-flex align-items-center justify-content-center">
                <img src={subscription} width="48px" className="img-fluid"/>
                </div>
                </div>
                 <div className="col-lg-10 col-md-10 col-sm-8 col-8">
                   <div className="farm-dashboard-title ps-3">
                  <h5>Subscription Plans</h5>
                </div>
                 </div>
              </div>
            </a>
            </div>

          </div>
        </div>
        <div className="col-xxl-12 col-md-12">
          <div className="card info-card sales-card">

           <div className="card-body">
             <a href="manage_farm.html">
              <div className="d-flex align-items-center">
                <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                   <div className="card-icon d-flex align-items-center justify-content-center">
                <img src={for_sale} width="48px" className="img-fluid"/>
                </div>
                </div>
                 <div className="col-lg-10 col-md-10 col-sm-8 col-8">
                   <div className="farm-dashboard-title ps-3">
                  <h5>Plot Edit</h5>
                </div>
                 </div>
              </div>
            </a>
            </div>

          </div>
        </div>
        <div className="col-xxl-12 col-md-12">
          <div className="card info-card sales-card">

          <div className="card-body">
             <a href="manage_farm.html">
              <div className="d-flex align-items-center">
                <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                   <div className="card-icon d-flex align-items-center justify-content-center">
                <img src={dna_test} width="48px" className="img-fluid"/>
                </div>
                </div>
                 <div className="col-lg-10 col-md-10 col-sm-8 col-8">
                   <div className="farm-dashboard-title ps-3">
                  <h5>Lab Test</h5>
                </div>
                 </div>
              </div>
            </a>
            </div>

          </div>
        </div>
  </div>

</section> */}

</main>


<section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="feature_dashboard.html">
        <i className="bi bi-shop"></i>
        <span>Home</span>
      </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="product-gallery.html">
        <i className="bi bi-handbag"></i>
        <span>Product Gallery</span>
      </a>
      </div>
    </div>
     
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="lab.html">
        <i className="bi bi-eyedropper"></i>
        <span>Lab</span>
      </a>
      </div>
    </div>

    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
         <a href="#">
        <i className="bi bi-share"></i>
        <span>Share</span>
      </a>
      </div>
    </div>

  {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}

   

  
  </nav>
</header>
        </div>
    </section>


    </div>
  )
}

export default PlotInfo
