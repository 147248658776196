import React,{useState} from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import axios, { Axios } from 'axios'
import {GET_DATA} from '../base_url/BaseUrl'

const Address = () => {

 
  const { setSideBar, sidebar } = useContext(AuthContextProvider)

  const [state,setState]=useState([])
  const[district,setDistrict]=useState([])
  const[taluka,setTaluka]=useState([])

  const Styles = {
    sidebar1:{
      marginLeft:""
    },
    sidebar2:{
      marginLeft:"00px"
    }
  }

  useEffect(() => {
    setSideBar(true)
  },[])

  useEffect(()=>{
    axios.post(GET_DATA,{
      START:"",
      END:"",
      WORD:"",
      GET_DATA:"State_List",
      ID1:"",
      ID2:"",
      ID3:"",
      STATUS:"",
      START_DATE:"",
      END_DATE:"",
      EXTRA1:"",
      EXTRA2:"",
      EXTRA3:"",
      LANG_ID:""
    }).then((responce)=>{
      console.log("State List API :",responce.data)
      setState(responce.data.DATA)
    })
  },[])


  const districtAPI=(i)=>{
    console.log("Selected State is :",i)
  }


  useEffect(()=>{
   
      axios.post(GET_DATA,{
      START:"",
      END:"",
      WORD:"",
      GET_DATA:"City_List",
      ID1:"1",
      ID2:"",
      ID3:"",
      STATUS:"",
      START_DATE:"",
      END_DATE:"",
      EXTRA1:"",
      EXTRA2:"",
      EXTRA3:"",
      LANG_ID:""
      }).then((responce)=>{
        console.log("DistrictList API :",responce.data.DATA)
        setDistrict(responce.data.DATA)
      })
    
  },[])


  useEffect(()=>{
    axios.post(GET_DATA,{
      START:"",
      END:"",
      WORD:"",
      GET_DATA:"Taluka_List",
      ID1:"1",
      ID2:"",
      ID3:"",
      STATUS:"",
      START_DATE:"",
      END_DATE:"",
      EXTRA1:"",
      EXTRA2:"",
      EXTRA3:"",
      LANG_ID:""
    }).then((responce)=>{
      console.log("TalukaAPI :",responce.data.DATA)
      setTaluka(responce.data.DATA)
    })
  },[])

  return (


    <div>
      
      <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>

      

<section className="section dashboard" id="Dashboard-form">
  <div className="row">

    <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card info-card sales-card">
            <div className="card-body" >
      <div className="row">
          




        <div className="col-md-12 col-sm-12 col-lg-12 d-none">
          <h5 className="card-title">Do you know your Pincode?</h5>
              <div className="d-flex align-items-center">

            <div className="form-inline text-center showHide_Div">
              <input className="form-check-input" type="radio" name="lang" value="show" onClick="showHideDiv(1)" checked/> <label>Yes</label>
              <input className="form-check-input" type="radio" name="lang" value="hide"  onClick="showHideDiv(2)"/> <label> No</label>
          </div> 

      </div>
        </div>

        <div className="row active ">
   
      <div className="xdemo1" id="xaddress1">
      <div className="row active">
      <div className="col-md-12 col-sm-12 col-lg-12">
     <h5 className="card-title">Add Your Address</h5>
   </div>
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">State</label>
              <select className="form-select minimal" name="state_name" id="state_name" districturl="/district/">
                 <option value="SELECT_STATE">Select State</option>
                  {
                    state.map((i,index)=>{
                      return(
                        <option value="state" onClick={()=>districtAPI(i)}>{i.STATE_NAME}</option>
                      )
                    })
                  }
                                            </select>
        </div>
      </div>
         <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">District</label>
             <select className="form-select minimal" name="district_name" id="district_name" talukaurl="/taluka/">
            <option value="Select District">Select District</option>
                  {
                   district.map((i,index)=>{
                    return(
                      <option value="district">{i.CITY_NAME}</option>

                    )

                   })

                    
                  }

</select>
        </div>
      </div>
          <div className="col-md-6 col-sm-6 col-lg-6">
            <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">Taluka</label>
              <select className="form-select minimal" name="taluka_name" id="taluka_name" mastervillageurl="/master_village/">

    <option value="taluka">Select Taluka</option>
                  
                  {
                    taluka.map((i,index)=>{
                      return(
                        <option value="taluka">{i.TALUKA_NAME}</option>
                        )
                    })
                  }

</select>
        </div>
      </div>
          <div className="col-md-6 col-sm-6 col-lg-6">
            <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">Village</label>

             <input type="text" name="village" className="form-control minimal" id="master_village" required placeholder="Enter Village"/>


             {/* <select className="form-control minimal" name="master_village" id="master_village">

    
        <option value="85903">Adivare</option>
        <option value="85925">Amondi</option>
        <option value="85937">Asane</option>      
        <option value="86031">Dimbhe Colony</option>
        <option value="86045">Gawadewadi</option>
        <option value="86046">Ghodegaon (Pune)</option>
        <option value="86050">Girawali</option>
        <option value="86054">Gohe BK</option>
        <option value="86085">Jambhori</option>
        <option value="86101">Kalamb (Pune)</option>
        <option value="86196">Kondhwal</option>
        <option value="86215">Kurwandi</option>
        <option value="86219">Kushire BK</option>
        <option value="86221">Lakhangaon</option>
        <option value="86223">Landewadi</option>
        <option value="86231">Loni Dhamani</option>
        <option value="86238">Mahalunge Padwal</option>
        <option value="86253">Manchar</option>
        <option value="86286">Narodi</option>
        <option value="86313">Nirgudsar</option>
        <option value="86336">Pargaon Tarfe Avasari</option>
        <option value="86348">Phulawade</option>
        <option value="86351">Pimpalgaon Mahalunge</option>
        <option value="86354">Pimpalgaon Tarfe Ghoda</option>
        <option value="86368">Pokhari</option>
        <option value="86376">Rajpur</option>
        <option value="86385">Ranjani</option>
        <option value="86403">Sakore</option>
        <option value="86406">Sal</option>
        <option value="86433">Shinoli</option>
        <option value="86467">Taleghar</option>
        <option value="86479">Thugaon</option>
        <option value="86480">Tirpad</option>
        <option value="86507">Vadgaon Kashimbeg</option>
        <option value="86509">Vadgaon Peer</option>
        <option value="86516">Valati</option>

<option value="Other">Other</option>

</select> */}
        </div>
      </div>



      <div className="col-md-6 col-sm-6 col-lg-6">
            <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">Pincode</label>

             <input type="text" name="village" className="form-control minimal" id="master_village" required placeholder="Enter Pincode"/>


        </div>
      </div>


      {/* <!--   <div className="col-md-12 col-sm-12 col-lg-12">
          <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">Sub Village Name</label>
              <input type="text" className="form-control" id="inputNanme4" placeholder="Enter Village Name">
        </div>
      </div> --> */}
   </div> 

   
     
    </div>
     <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
      <a href="./featureDashbord" className="btn_new btn-success text-center">Submit</a>
      {/* <!-- <button className="btn btn-success text-center" type="submit">Submit </button> --> */}
      </div>
  </div>


      
    </div>
    </div>
  </div>
</div>


  </div>
</section>

</main>


<section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
    <div className="container">
        <header className="mob-footer">
<nav className="bottom-nav">
<div className="bottom-nav-item active">
  <div className="bottom-nav-link">
    <a href="feature_dashboard.html">
    <i className="bi bi-shop"></i>
    <span>Home</span>
  </a>
  </div>
</div>
<div className="bottom-nav-item">
  <div className="bottom-nav-link">
    <a href="product-gallery.html">
    <i className="bi bi-handbag"></i>
    <span>Product Gallery</span>
  </a>
  </div>
</div>
 
<div className="bottom-nav-item">
  <div className="bottom-nav-link">
    <a href="lab.html">
    <i className="bi bi-eyedropper"></i>
    <span>Lab</span>
  </a>
  </div>
</div>

<div className="bottom-nav-item">
  <div className="bottom-nav-link">
     <a href="#">
    <i className="bi bi-share"></i>
    <span>Share</span>
  </a>
  </div>
</div>

{/* <!-- <div className="bottom-nav-item">
  <div className="bottom-nav-link">
    <i className="bi bi-question"></i>
    <span>Questions</span>
  </div>
</div> --> */}




</nav>
</header>
    </div>
</section>



    </div>
  )
}

export default Address
