import React,{useState} from 'react'
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css";

const PlotRegistrationForm = () => {



    // const Example = () => {
    //     const [startDate, setStartDate] = useState(new Date());
    //     return (
    //       <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
    //     );
    //   };

  return (
    <div>
      
      <main id="main" className="main">

      

<section className="section dashboard" id="Dashboard-form">
  <div className="row">

    <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card info-card sales-card">
            <div className="card-body" >
      <div className="row">
          




        <div className="col-md-12 col-sm-12 col-lg-12 d-none">
          <h5 className="card-title">Do you know your Pincode?</h5>
              <div className="d-flex align-items-center">

            <div className="form-inline text-center  showHide_Div">
              <input className="form-check-input" type="radio" name="lang" value="show" onClick="showHideDiv(1)" checked/> <label>Yes</label>
              <input className="form-check-input" type="radio" name="lang" value="hide"  onClick="showHideDiv(2)"/> <label> No</label>
          </div> 

      </div>
        </div>

        <div className="row active ">

      <div className="xdemo1" id="xaddress1">
      <div className="row active">
      <div className="col-md-12 col-sm-12 col-lg-12">
     <h5 className="card-title">Fill the information</h5>
   </div>
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">Crop Variety</label>
              <select className="form-select minimal" name="state_name" id="state_name" districturl="/district/">
                 <option value="VARIETY1">Select Variety</option>
                 <option value="VARIETY1">Variety1</option>
                   <option value="VARIETY2">Variety2</option>
                  <option value="VARIETY3">Variety3</option>
                   <option value="VARIETY4">Variety4</option>
                                  
            </select>
        </div>
      </div>
         <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">Plantation Date</label>
             <input type='date' name="village" className="form-control minimal" id="master_village" required placeholder="Select Date"/>

        </div>
      </div>
          <div className="col-md-6 col-sm-6 col-lg-6">
            <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">Plantation Area</label>
             <input type='text' name="village" className="form-control minimal" id="master_village" required placeholder="Enter Plantation Area"/>


        </div>
      </div>

      <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">Soil Type</label>
              <select className="form-select minimal" name="state_name" id="state_name" districturl="/district/">
                 <option value="VARIETY1">Select Soil Type</option>
                   <option value="VARIETY2">Soil Type 1</option>
                  <option value="VARIETY3">Soil Type 2</option>
                   <option value="VARIETY4">Soil Type 3</option>
                                  
            </select>
        </div>
      </div>

          <div className="col-md-6 col-sm-6 col-lg-6">
            <div className="form-group">
             <label htmlFor="inputNanme4" className="form-label">Previous Year Problems</label>

             <input type="text" name="village" className="form-control minimal" id="master_village" required placeholder="Enter Previous Year Problems"/>


        </div>
      </div>


   </div> 

   
     
    </div>
     <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
      <a href="./featureDashbord" className="btn_new btn-success text-center">Submit</a>
      {/* <!-- <button className="btn btn-success text-center" type="submit">Submit </button> --> */}
      </div>
  </div>


      
    </div>
    </div>
  </div>
</div>


  </div>
</section>

</main>

    </div>
  )
}

export default PlotRegistrationForm
