import React from 'react'
import marathiLang from '../img/Marathi1.jpg'
import Hindi1 from '../img/Hindi1.jpg'
import English1 from '../img/English1.jpg'
import { useNavigate } from 'react-router-dom'

const Language = () => {
    const navigate = useNavigate()
    const marathi = () => {        
        
        navigate('/login')
    }

    const hindi = () => {        
        
        navigate('/login')
    }

    const english = () => {        
        
        navigate('/login')
    }


  return (
    <div>
      
      <main>
      <section id="language-page" className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container-fluid">
          <div className="row justify-content-center">

            <div className="col-lg-6 col-md-6">
              <section className="demo-section section-center" id="home">
            <div className="title">
                <h2>Choose Language</h2><br/>
                <h2 className="text-center">भाषा चुनिए</h2>
            </div>
            <div className="custom-container">
                <div className="row demo-block demo-imgs">
                    <div className="col-lg-12 col-sm-12">
                        <ul className="language-text">
                            
                            <li id="hindi_2" className="lang-list" onClick={() => marathi()}>
                                <a className="" href="login.html">
                                    <img className="lang-img" src={marathiLang} alt=""/>
                                    <span className="lang-text">मराठी</span>
                                </a>
                            </li>
                            
                            <li id="hindi_1" className="lang-list" onClick={() => hindi()} >
                                <a className="" href="login.html">
                                    <img className="lang-img" src={Hindi1} alt=""/>
                                    <span className="lang-text">हिंदी</span>
                                </a>
                            </li>
                            
                            <li id="hindi_3" className="lang-list" onClick={() => english()}>
                                <a className="" href="login.html">
                                    <img className="lang-img" src={English1} alt=""/>
                                    <span className="lang-text">English</span>
                                </a>
                            </li>
                            
                        </ul>
                        {/* <!-- <script>
                            function change_lang (lang) {
                                window.location.href = '/change_language/'+'?lang='+lang
                            };
                        </script> --> */}
                    </div>
                </div>
            </div>
        </section>
            </div>
            
            
          </div>
        </div>

      </section>
  </main>


    </div>
  )
}

export default Language
