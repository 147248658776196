import React, { useState } from 'react'
import { useContext } from 'react'
import logo from '../img/logo.png'
import { AuthContextProvider } from '../useContext/AuthContext'
import axios from 'axios'
import { CART } from '../base_url/BaseUrl'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
const Navbar = () => {
  const [products, setProducts] = useState([]);
  const USER_ID = localStorage.getItem("USER_ID");
  const totalProducts = products.length;
  const navigate = useNavigate()
  // const[navBar,setNavBar] = useState(false)
  const { sidebar, setSideBar, navBar, setNavBar } = useContext(AuthContextProvider)

  const MOBILE_NO = localStorage.getItem("MOBILE_NO")
  const FULL_NAME = localStorage.getItem("FULL_NAME")

  const Styles = {
    sidebar1: { marginLeft: "-300px" },
    sidebar2: { marginLeft: "" },
    navbar1: { marginTop: "-65px" },
    navbar2: { marginTop: "" }
  }


  useEffect(() => {
    axios.post(`${CART}?USER_ID=${USER_ID}&TYPE=all`, {
      START: "0",
      END: "10000",
      WORD: "None",
      USER_ID: USER_ID,
      TYPE: "1"
    }).then((responce) => {
      console.log(responce.data)
      setProducts(responce.data.DATA)
    })
  }, [])

  const cartinfo = (e) => {
    navigate('/cart');
  }

  return (
    <div>
      <header id="mob-header" className="header fixed-top d-flex align-items-center main-page d-lg-none"  >

        <div className="d-flex align-items-center justify-content-between">
          <div className="mob-navbar">
            <div className="d-flex align-items-center justify-content-between">
              <i className="bi bi-list toggle-sidebar-btn" onClick={() => {
            setSideBar(!sidebar)
          }}  />
              <a onClick={() => navigate('/')} className="logo d-flex align-items-center">
                <img src={logo} alt="" />
              </a>

              {/* <a href="./featureDashbord"><i className="bi bi-arrow-left"></i></a>
      <div className=""><h6>Add Question &amp; Answer</h6></div>  */}

            </div>
          </div>
        </div>

      </header>


      <header id="header" className="header fixed-top d-flex align-items-center main-page d-sm-none d-xs-none d-lg-block" style={navBar ? Styles.navbar2 : Styles.navbar1}>

        <div className="d-flex align-items-center justify-content-between">          
          <a onClick={() => navigate('/featureDashbord')} className="logo d-flex align-items-center">
            <img src={logo} alt="" />
          </a>

        </div>
        {/* <nav class="header-nav ms-auto">
          <ul class="landing-menu nav nav-pills">
            <li class="nav-item"><a class="nav-link" href="signup.html">SignIn</a></li>
            <li class="nav-item"><a class="nav-link" href="javascript:void(0)" data-bs-toggle="modal"
              data-bs-target=".language-modal" onclick="showLanguage()">English</a></li>
          </ul>
        </nav> */}
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">


            <li className="nav-item d-block d-lg-none">
              <a className="nav-link nav-icon search-bar-toggle " >
                <i className="bi bi-search"></i>
              </a>
            </li>

            <li className="nav-item ">

              <a className="nav-link nav-icon" onClick={cartinfo} data-bs-toggle="dropdown">
                <i class="bi bi-cart" ></i>
                <span className="badge bg-primary badge-number">{totalProducts}</span>
              </a>

            </li>

            <li className="nav-item dropdown">

              <a className="nav-link nav-icon"  data-bs-toggle="dropdown">
                <i className="bi bi-bell"></i>
                <span className="badge bg-primary badge-number">4</span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li className="dropdown-header">
                  You have 4 new notifications123
                  <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-exclamation-circle text-warning"></i>
                  <div>
                    <h4>Disease alert (0)</h4>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-x-circle text-danger"></i>
                  <div>
                    <h4>Call Visit Request (0)</h4>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-check-circle text-success"></i>
                  <div>
                    <h4>Scouting Report (0)</h4>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-info-circle text-primary"></i>
                  <div>
                    <h4>Nursery Request (0)</h4>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="dropdown-footer">
                  <a href="#">Show all notifications</a>
                </li>

              </ul>

            </li>


            <li className="nav-item dropdown pe-3">

              <a className="nav-link nav-profile d-flex align-items-center pe-0"  data-bs-toggle="dropdown">
                <span className="d-none d-md-block dropdown-toggle ps-2">{FULL_NAME}</span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6>{FULL_NAME}</h6>
                  <span>Web Designer</span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a className="dropdown-item d-flex align-items-center" href="#">
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a className="dropdown-item d-flex align-items-center" href="./login">
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </a>
                </li>

              </ul>
            </li>

          </ul>
        </nav>

      </header>

      <aside id="sidebar" className="sidebar" style={sidebar ? Styles.sidebar2 : Styles.sidebar1}>

        <ul className="sidebar-nav" id="sidebar-nav">

          <li className="nav-item">
            <a className="nav-link " >
            <i class="bi bi-house-door-fill"></i>
              <span>Home</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" >
            <i class="bi bi-cart3"></i>
              <span>My Order</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed" >
            <i className="bi bi-person"></i>
              <span>Profile</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed" >
            <i className="bi bi-share"></i>
              <span>Share</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed" >
            <i class="bi bi-star"></i>
              <span>Rate App</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" >
            <i class="bi bi-chat-dots"></i>
              <span>Contact Us</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" >
            <i class="bi bi-info-circle"></i>
              <span>About Us</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed" >
            <i className="bi bi-shuffle"></i>
              <span>Language Change</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed" >
            <i class="bi bi-box-arrow-right"></i>
              <span>Logout</span>
            </a>
          </li>

        </ul>

      </aside>




    </div>
  )
}

export default Navbar
