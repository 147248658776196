import React from 'react'
import pest from '../img/pest.png'
import diseaseimg from '../img/disease-img.jpg'

const DiseaseControl = () => {
  return (
    <div>
      
      <main id="main" className="main">

<section className="section dashboard" id="disease">
  <div className="row">

    <div className="col-lg-12 col-md-12 col-sm-12">
         <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="pagetitle">
              <h1>श्रेणी </h1>
           </div>
         </div>
          <div className="card info-card sales-card">
            <div className="card-body">

            <div className="row">
                <div className="col-lg-3 col-md-3 co-sm-3 col-3">
                  <a href="#">
                    <div className="product-ouetr-blk">
                      <div className="farm-prodct-icon-circle">
                        <img src={pest} className="img-fluid" width="48px"/>
                      </div>
                      Category1
                    </div>
                  </a>
                </div>
                <div className="col-lg-3 col-md-3 co-sm-3 col-3">
                  <a href="#">
                    <div className="product-ouetr-blk">
                      <div className="farm-prodct-icon-circle">
                        <img src={pest} className="img-fluid" width="48px"/>
                      </div>
                      Category2
                    </div>
                  </a>
                </div>
                <div className="col-lg-3 col-md-3 co-sm-3 col-3">
                  <a href="#">
                    <div className="product-ouetr-blk">
                      <div className="farm-prodct-icon-circle">
                        <img src={pest} className="img-fluid" width="48px"/>
                      </div>
                      Category3
                    </div>
                  </a>
                </div>
                <div className="col-lg-3 col-md-3 co-sm-3 col-3">
                  <a href="#">
                    <div className="product-ouetr-blk">
                      <div className="farm-prodct-icon-circle">
                        <img src={pest} className="img-fluid" width="48px"/>
                      </div>
                      Category4
                    </div>
                  </a>
                </div>
            </div>
        </div>
  </div>
</div>

<div className="row">
  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
    <div className="card info-card sales-card">
        <div className="card-body">
          <img src={diseaseimg} className="img-fluid" alt=""/>
        </div>
    </div>
    
  </div>
   <div className="col-lg-6 col-md-6 col-sm-12 col-12">
    <div className="card info-card sales-card">
        <div className="card-body">
          <img src={diseaseimg} className="img-fluid" alt=""/>
        </div>
    </div>
    
  </div>
</div>



  </div>
</section>

</main>

<section className="mobile-navbar d-md-none d-sm-block" id="mobile-view-menu">
        <div className="container">
            <header className="mob-footer">
  <nav className="bottom-nav">
    <div className="bottom-nav-item active">
      <div className="bottom-nav-link">
        <a href="feature_dashboard.html">
        <i className="bi bi-shop"></i>
        <span>Home</span>
      </a>
      </div>
    </div>
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="product-gallery.html">
        <i className="bi bi-handbag"></i>
        <span>Product Gallery</span>
      </a>
      </div>
    </div>
     
    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <a href="lab.html">
        <i className="bi bi-eyedropper"></i>
        <span>Lab</span>
      </a>
      </div>
    </div>

    <div className="bottom-nav-item">
      <div className="bottom-nav-link">
         <a href="#">
        <i className="bi bi-share"></i>
        <span>Share</span>
      </a>
      </div>
    </div>

  {/* <!-- <div className="bottom-nav-item">
      <div className="bottom-nav-link">
        <i className="bi bi-question"></i>
        <span>Questions</span>
      </div>
    </div> --> */}

   

  
  </nav>
</header>
        </div>
    </section>



    </div>
  )
}

export default DiseaseControl
