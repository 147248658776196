export const BASE_URL ="https://swaroopaagromartapii.greemgoldfpc.com/api/"
export const BASE_URL1 ="https://kissanassistfarmerapi.greemgoldfpc.com/api/"
export const WEATHER_URL=`https://api.openweathermap.org/data/2.5/forecast?lat=20.0086734&lon=73.763923&appid=f63af5e4778e517ec5e7599d6172eae5`
export const  ENQUIRY ="https://swaroopaagromartapii.greemgoldfpc.com/api/Enquiry"


export const PRODUCT_CATEGORY =BASE_URL+"Category"
export const CART =BASE_URL+"Cart"
export const OTP =BASE_URL+"OTP_Mobile"
export const USER_REGISTRATION =BASE_URL+"User_Registration"
export const GET_PRODUCT_IMAGES =BASE_URL+"GetProductImages"
export const GET_PRODUCT_SIZE =BASE_URL+"ProductSize"
export const GET_PRODUCT_LIST =BASE_URL+"ProductList"


export const GET_DATA =BASE_URL1+"Get_Data"
export const PLOT_MASTER =BASE_URL1+"PlotMaster"
export const PLOT_LIST =BASE_URL1+"PlotMaster/1"
export const LAB_ENQUIRY =BASE_URL1+"LabEnquiry/1"
export const LAB_ENQUIRY_LIST =BASE_URL1+"LabEnquiry"
