import React, { useState } from 'react'
import { useContext } from 'react'
import { AuthContextProvider } from '../useContext/AuthContext'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { CART } from '../base_url/BaseUrl'
import { ENQUIRY } from '../base_url/BaseUrl'
import CreateIcon from '@mui/icons-material/Create';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Button } from 'bootstrap'
const Checkout = () => {
  var total = 0;
  var savetotal = 0;
  var grandtotal = 0;
  const { setSideBar, sidebar } = useContext(AuthContextProvider)
  const [products, setProducts] = useState([]);
  const [products1, setProducts1] = useState([]);
  const [scharge, setScharge] = useState();
  const totalProducts = products.length;
  const navigate = useNavigate()
  const USER_ID = localStorage.getItem("USER_ID")
  const [amount, setamount] = useState('')

  const[deliveryadd,setDeliveryAdd]=useState("")
  const[deliveryadd1,setDeliveryAdd1]=useState(false)
  const[deliverydetail,setDeliveryDetail]=useState("")
  const[deliverydetail1,setDeliveryDetail1]=useState(false)
  const[paymentmethod,setPaymentMethod]=useState("")
  const[paymentmethod1,setPaymentMethod1]=useState(false)

  const  loarea=localStorage.getItem("area")
  const lobuildingname=localStorage.getItem("buildingname")
  const lolandmark=localStorage.getItem("landmark")
  const  locity=localStorage.getItem("city")
  const lomobileno=localStorage.getItem("MOBILE_NO")

  const[loarea1,setLoarea1]=useState(false)

  const Styles = {
    sidebar1: {
      marginLeft: ""
    },
    sidebar2: {
      marginLeft: "00px"
    }
  }

  const Styles1={
    username:{
      color:"red"
    },
    username2:{
      display:"none"
    }
    
  }



  useEffect(() => {
    setSideBar(true)
    console.log("loarea",loarea);
  }, [])


  useEffect(() => {
    axios.post(`${CART}?USER_ID=${USER_ID}&TYPE=all`, {
      START: "0",
      END: "10000",
      WORD: "None",
      USER_ID: USER_ID,
      TYPE: "1"
    }).then((responce) => {
      console.log("checkout", responce.data)
      console.log(responce.data.DATA[0].SHIPPING_CHARGES)


     

      // console.log("pid",responce.data.DATA[0].PRODUCT_ID)
      responce.data.DATA.forEach((employee, index) => {
        console.log("demo", employee.PRODUCT_ID);
        
      })

      setProducts(responce.data.DATA)
      console.log("res", responce.data.DATA);
      setScharge(responce.data.DATA[0].SHIPPING_CHARGES)
    })
  }, [products])

  const handleclick = (e) => {
    navigate('/adddeliverylocation');
  }


  const handlesubmit = (e) => {
    e.preventDefault();
    
    if (!loarea) {
      return setLoarea1(true)
    }
    // else if(!deliveryadd){
    //   return setDeliveryAdd1(true)
    // }
    else if(!deliverydetail){
      return setDeliveryDetail1(true)
    }
    else if(!paymentmethod){
return setPaymentMethod1(true)
    }
     else if(paymentmethod=="Cash On Delivery"){
alert('Are you sure you want to place order?');
     }
    else {
      var options = {
        key: "rzp_test_S6OR5dHSpqC4ri",
        key_secret: "",
        amount: grandtotal * 100,
        currency: "INR",
        name: "STARTUP_PROJECT",
        description: "for testing purpose",
        handler: function (response) {
          // console.log("response",response)
          alert(response.razorpay_payment_id);
          axios.post(ENQUIRY,
            {
              "USER_ID": USER_ID,
              "TOTAL_QTY": `${totalProducts}`,
              "TOTAL_PRICE": `${grandtotal}`,
              "TOTAL_DISC": `${savetotal}`,
              "ORDER_ADDRESS": "testing  testing  nashik Nashik 9999999999",
              "SHIPING_CHARGES": `${scharge}`,
              "PRODUCT_ID": "1",
              "PRODUCTSIZE_ID": "9",
              "STATE_ID": "",
              "DISTRICT_ID": "",
              "VILLAGE_ID": "0",
              "TALUKA_ID": "",
              "PINCODE": "",
              "GST": "1",
              "RATE": "500.0",
              "QTY": "1.0",
              "TIME_SLOT": "1",
              "ORDER_DATE": "04\/07\/2023",
              "DISCOUNT": "50.0",
              "PROMOCODE_ID": "1",
              "PROMOCODE_AMT": "1",
              "MEMBERCODE_ID": "1",
              "USER_TYPE": "COD",
              "LATITUDE": "72.6767676",
              "LONGITUDE": "82.565656",
              "ORDER_INSTRUCTION": "Time to send product delivery ",
              "EXTRA": "1"
            }).then((responce) => {
              console.log("response", responce.data)

            })




        },
        prefill: {
          name: "kaveri",
          email: "k@gmail.com",
          contact: "9158354258"
        },
        notes: {
          address: "Razorpay Corporate office"
        },
        theme: {
          color: "#3399cc"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }


  return (
    <div >
      <main id="main" className="main" style={sidebar ? Styles.sidebar1 : Styles.sidebar2}>
        <section className="section dashboard" id="disease" >
          <div className="row" >

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="card info-card sales-card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-4">Product</div>
                    <div className="col-3">Quantity</div>
                    <div className="col-3">Amount</div>
                  </div>
                </div>
                <div className="card-body">
                  {
                    products.map((i, index) => {

                      return (
                        <div className="row">
                          <div className="col-2"><img src={i.P_IMAGE} className="prod_img" width="40px" /></div>
                          <div className="col-4">{i.P_NAME}</div>
                          <div className="col-3"> {i.QTY}</div>
                          <div className="col-3">{i.P_PRICE - i.P_DESC}</div>

                          <hr className="my-2" />
                        </div>
                      )
                    })
                  }


                </div>
                
                    
                
              </div>

              <div className="card info-card sales-card">
                <div className="card-body">
                  <p className="main_text">Delivery Details</p>
                  {/* <i class="bi bi-pencil-fill"></i> */}

                  {/* <textarea   style={{width:"100%"}}  placeholder=' Enter Delivery Details'  
                       InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccessTimeFilledIcon />
                          </InputAdornment>
                        ),
                      }}
                        /> */}

                  <TextField id="outlined-basic" onChange={(e)=>{setDeliveryDetail(e.target.value)}} style={{ width: "345px" }} variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CreateIcon color="success" />
                        </InputAdornment>
                      ),
                    }}
                  />

                </div>
              </div>
              <div>
                        <p style={deliverydetail1 ? Styles1.username : Styles1.username2}>Select delivery details</p>
                      </div>

            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="card info-card sales-card">
                <div className="card-body">
                  {/* <p className="main_text"  >Delivery Address</p> */}
                  <Typography >Delivery Address<IconButton style={{ marginLeft: "175px" }}   onClick={handleclick}> <CreateIcon color="success" /></IconButton></Typography  >
                  {loarea === "" ? (
                  <p>Select Delevery Address</p>
                  ) :  (
                    <p>{loarea} {lobuildingname} {locity} {lolandmark} {lomobileno}</p>
                  )}
                </div>
                <div>
                        <p style={loarea1 ? Styles1.username : Styles1.username2}>Select shipping address</p>
                      </div>
              </div>


              <div className="card info-card sales-card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" className="main_text" >Billing Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Total Price</td>

                          {
                            products.map((i, index) => {


                              total = total + i.P_PRICE

                            })
                          }
                          <td className="amount">₹{total}</td>
                        </tr>
                        <tr>
                          <td>Total Product</td>
                          <td className="amount">{totalProducts}</td>
                        </tr>
                        <tr>
                          <td>You Save</td>
                          {
                            products.map((i, index) => {


                              savetotal = savetotal + i.P_DESC

                            })
                          }
                          <td className="amount">₹{savetotal}</td>
                        </tr>
                        <tr style={{ borderBottom: "2px solid green" }}>
                          <td>Shipping Charges</td>
                          <td className="amount">₹{scharge}</td>
                        </tr>


                        <tr style={{ borderBottom: "2px solid green" }}>
                          <td className="main_text" style={{ fontWeight: "bold" }} >Grand Total</td>
                          <td className="main_text" style={{ fontWeight: "bold" }}>₹{grandtotal = (total + scharge) - savetotal}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="card info-card sales-card">
                <div className="card-body">
                  <div className="main_text" >
                    Payment Method
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Cash On Delivery
                    </label>
                    <input className="form-check-input" type="radio" name="flexRadioDefault"  onChange={(e)=>{setPaymentMethod(e.target.value)}} id="flexRadioDefault1" />
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                      Pay Online
                    </label>
                    <input className="form-check-input" type="radio" name="flexRadioDefault"  onChange={(e)=>{setPaymentMethod(e.target.value)}} id="flexRadioDefault2" />
                  </div>
                  <div>
                        <p style={paymentmethod1 ? Styles1.username : Styles1.username2}> Select payment method</p>
                      </div>
                </div>
              </div>

            </div>

          </div>


          {/* <div className="responsive_checkout_btn row">
          <div className="checkout_btn">
              <p className="checkout">Place Your Order</p>
          </div>
      </div> */}


          <div className="col-md-12 col-sm-12 col-lg-12 mr-t2">
            <div className="mob-view-btn">
              <a href='./demo' className="btn_new btn-success text-center" onClick={handlesubmit}>place your order</a>
              {/* <!-- <button className="btn btn-success text-center" type="submit">Submit</button> --> */}
            </div>
          </div>





        </section>
      </main>
    </div>
  )
}

export default Checkout


